import { Avatar, Button, Card, CardMedia, Grid, Input } from "@mui/material";
import { useEffect, useState } from "react";
import ConfLogo from "./confAdministracion/confLogo";

export const ConfAdministracion = ({ open, errorsMgr, data = [], logo, asignaLogo = () => {}, disabled }) => {

  const getValueAttr = (attr, id) => {
    switch (attr) {
      case "desc":
        return data?.find((x) => x.idVariable === id).descripcion;
      case "name":
        return data?.find((x) => x.idVariable === id).nombreVariable;
      default:
        return data?.find((x) => x.idVariable === id).valor;
    }
  };

  const getErrorMsg = (id) => {
    let msg = "";
    if (errorsMgr === null || errorsMgr === undefined) return msg;

    Object.entries(errorsMgr)?.map((p) => {
      if (p[0] === getValueAttr("name", id)) msg = p[1];
    });

    return msg;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={6} md={3}>
        <ConfLogo logo={logo} onChangeLogo={asignaLogo} disabled={disabled}/>
      </Grid>
    </Grid>
  );
};
