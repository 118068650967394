import { Catalogo, ColumnAlign, DangerMessage, WarningMessage } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAll } from "../../store/catalogos/estaciones";

const TABLE_DEFAULT = {
  columns: [
    { text: "Nombre", name: "nombre", colAlign: ColumnAlign.Left, orderDefault: true },
    { text: "CRE", name: "cre", colAlign: ColumnAlign.Left },
  ],
};

export function Estaciones() {
  const dispatch = useDispatch();
  const { loading, stations, error } = useSelector((state) => state.estaciones);
  
  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);
  
  const handleRefresh = () => {
    dispatch(getAll());
  };
  const handleError = () => {
    if (Boolean(error.status)) {
      error.status === 409 ? warningMsg.open() : warningMsg.close();
      error.status === 500 ? dangerMsg.open() : dangerMsg.close();
    }
  };
  
  useEffect(handleError, [error]);

  return (
    <>
      <Catalogo title='Estaciones' table={TABLE_DEFAULT} data={stations} loading={loading} onRefresh={handleRefresh} />

      <DangerMessage open={dangerMsg.isShowing} onClose={dangerMsg.close} text={error.message} />
      <WarningMessage open={warningMsg.isShowing} onClose={warningMsg.close} text={error.message} />
    </>
  );
}
