import { UserManager } from "oidc-client";
import { UpdateEstacionTrabajo } from "../store/catalogos/usuarios";
import { SETTINGS_OPENID } from "../utils/authConst";
import { GetStations } from "./services/catalogos/usuarios";

const userManager = new UserManager(SETTINGS_OPENID);

export const isAuth = async () => {
  const user = await getUser();

  if (user === "undefined" || user === null) return 401;
  if (user.expired) return 401;
  if (user.profile.activo !== "True") return 403;

  const token = user.access_token;
  if (!token) return 401;

  let idAPI = Array.isArray(user.profile.aud)
    ? user.profile.aud.find((e) => e === SETTINGS_OPENID.gateway_id)
    : user.profile.aud === SETTINGS_OPENID.gateway_id;
  if (idAPI) return 200;
  else return 403;
};

export const login = async () => {
  localStorage.clear();
  await userManager.signinRedirect();
};

export const logout = async () => {
  localStorage.clear();
  await userManager.signoutRedirect();
};

export const getUser = async () => {
  return await userManager.getUser();
};

export const getStation = async () => {
  let _usr = await getUser();
  return _usr?.profile.idEstacion;
};

export const getSubscription = async () => {
  let _usr = await getUser();
  return _usr?.profile.subscription;
};

export const getStations = async (sub) => {
  try {
    return await GetStations(sub);
  } catch (error) {
    throw new Error(error);
  }
};

export const updateStation = async (wrkStt) => {
  try {
    return await UpdateEstacionTrabajo(wrkStt);
  } catch (error) {
    throw new error(error);
  }
};
