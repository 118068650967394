import { UseAuthContext } from "../../../contexts";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { logout } from "../../../api/authService";
import { boldText } from "../../../helpers/utils";

export default function LogoutDialog() {
  const { logoutDialog, profile, setBackdrop } = UseAuthContext();

  async function handleLogout() {
    setBackdrop(true);
    await logout();
  }

  return (
    <div>
      <Dialog
        open={logoutDialog.isShowing}
        onClose={logoutDialog.open}
        disableEscapeKeyDown
        fullWidth
        maxWidth='sm'
        scroll='paper'
      >
        <DialogTitle>{"Cerrar Sesión"}</DialogTitle>
        <DialogContent>
          <DialogContentText>¿Desea cerrar sesión del usuario {boldText(profile.fullName)}?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={logoutDialog.close} color='secondary'>
            Cancelar
          </Button>
          <Button onClick={handleLogout} color='secondary' autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
