import { DangerMessage, SuccessMessage, WarningMessage } from "@paul-igas/igas-react-components";
import { UseAuthContext } from "../../../contexts";
import { ALERTMESSAGETYPES } from "../../../store/aplicacion";

export const AlertMessage = () => {
  const { alertMessage, onCloseAlertMessage } = UseAuthContext();

  if (!alertMessage.type) return null;
  if (alertMessage.type === ALERTMESSAGETYPES.SUCCESS)
    return <SuccessMessage open={true} text={alertMessage.text} onClose={onCloseAlertMessage} />;
  if (alertMessage.type === ALERTMESSAGETYPES.WARNING)
    return <WarningMessage open={true} text={alertMessage.text} onClose={onCloseAlertMessage} />;
  if (alertMessage.type === ALERTMESSAGETYPES.DANGER)
    return <DangerMessage open={true} text={alertMessage.text} onClose={onCloseAlertMessage} />;
};
