import { useDispatch, useSelector } from "react-redux";
import {
  ColumnAlign,
  Catalogo,
  ColumnType,
  WarningMessage,
  DangerMessage,
  ColumnPriority,
} from "@paul-igas/igas-react-components";
import { useModal, usePopper } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { InsertDialog, DetailDialog, EditDialog, DeleteDialog } from "../../components/operaciones/lecturasIniciales";
import { selectAll, setLecturaInicialIdSelected, clearErrors } from "../../store/operaciones/lecturasIniciales";
import { getUltimoAbierto } from "../../store/consultas/turnoLiquidacion";
import { Backspace, Edit, MoreVert, Pageview } from "@mui/icons-material";
import {
  Button,
  ClickAwayListener,
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Paper,
  Popper,
} from "@mui/material";
import { hasDispatchError } from "../../api/services/service";

const TABLE_DEFAULT = {
  columns: [
    { text: "Fecha", name: "fecha", colAlign: ColumnAlign.Center, colType: ColumnType.Date },
    { text: "Turno", name: "noTurno", colAlign: ColumnAlign.Center, colType: ColumnType.DataKey, digitos: 2 },
    {
      text: "Fecha turno",
      textAlt: "Fec.turno",
      name: "fechaTurno",
      colAlign: ColumnAlign.Center,
      colType: ColumnType.Date,
    },
    { text: "Técnico", name: "tecnico", colPriority: ColumnPriority.Normal },
    { text: "", name: "action", colAlign: ColumnAlign.Center, options: true, icon: true },
  ],
};
export function LecturasIniciales() {
  const dispatch = useDispatch();
  const { loading, lecturasIniciales, error } = useSelector((state) => state.lecturasIniciales);
  const [msgAlert, setMsgAlert] = useState("");
  const modalEdit = useModal(false);
  const modalInsert = useModal(false);
  const modalDetail = useModal(false);
  const modalDelete = useModal(false);
  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);
  const alertaMsg = useModal(false);

  const handleRefresh = (_) => dispatch(selectAll());

  const handleSelectLecturaInicial = (id) => {
    dispatch(setLecturaInicialIdSelected(id));
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error?.status === 409 && warningMsg.open();
      error?.status === 500 && dangerMsg.open();
    }
  };

  const validaTurno = ({ payload }) => {
    if ((payload?.id ?? 0) === 0) {
      setMsgAlert("No se encontró turno abierto.");
      alertaMsg.open();
      return;
    }
    let esValido = lecturasIniciales.some((x) => x.idTurno === payload.id);
    if (esValido) {
      setMsgAlert("Ya existe una lectura programada para el último turno abierto.");
      alertaMsg.open();
    } else {
      modalInsert.open();
    }
  };

  const handleInsert = async () => {
    dispatch(getUltimoAbierto())
      .then(hasDispatchError)
      .then(validaTurno)
      .catch(() => {});
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  useEffect(handleError, [error]);  

  return (
    <>
      <Catalogo
        title='Lecturas iniciales'
        table={TABLE_DEFAULT}
        data={lecturasIniciales}
        loading={loading.getAll}
        onRefresh={handleRefresh}
        onSelect={handleSelectLecturaInicial}
        onInsert={handleInsert}
        rowOptions={<OptionTableRow onDetail={modalDetail.open} onEdit={modalEdit.open} onDelete={modalDelete.open} />}
      />
      <InsertDialog open={modalInsert.isShowing} onClose={modalInsert.close} postSubmit={handleRefresh} />
      <DetailDialog open={modalDetail.isShowing} onClose={modalDetail.close} postSubmit={handleRefresh} />
      <EditDialog open={modalEdit.isShowing} onClose={modalEdit.close} postSubmit={handleRefresh} />
      <DeleteDialog open={modalDelete.isShowing} onClose={modalDelete.close} postSubmit={handleRefresh} />
      <WarningMessage open={alertaMsg.isShowing} onClose={alertaMsg.close} text={msgAlert} />
      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
    </>
  );
}

export function OptionTableRow(props) {
  const { data = {}, onEdit = () => {}, onDetail = () => {}, onDelete = () => {} } = props;
  const dispatch = useDispatch();
  const menu = usePopper(false);
  const selectLecturaInicial = (id) => dispatch(setLecturaInicialIdSelected(id));
  const handleEdit = () => {
    menu.close();
    selectLecturaInicial(data.id);
    onEdit();
  };
  const handleDetail = () => {
    menu.close();
    selectLecturaInicial(data.id);
    onDetail();
  };
  const handleDelete = () => {
    menu.close();
    selectLecturaInicial(data.id);
    onDelete();
  };
  return (
    <>
      <Button size='small' disableElevation style={{ minWidth: 0 }} onClick={menu.open}>
        <MoreVert fontSize='small' color='action' />
      </Button>
      {Boolean(menu.anchorEl) && (
        <Popper open={menu.isShowing} anchorEl={menu.anchorEl} placement={"bottom-end"}>
          <ClickAwayListener onClickAway={menu.close}>
            <Paper>
              <List dense={true}>
                <ListItemButton onClick={handleDetail}>
                  <ListItemIcon>
                    <Pageview fontSize='small' />
                  </ListItemIcon>
                  <ListItemText primary='Ver detalle' />
                </ListItemButton>
                <ListItemButton onClick={handleEdit}>
                  <ListItemIcon>
                    <Edit fontSize='small' />
                  </ListItemIcon>
                  <ListItemText primary='Editar' />
                </ListItemButton>
                <ListItemButton onClick={handleDelete}>
                  <ListItemIcon>
                    <Backspace fontSize='small' />
                  </ListItemIcon>
                  <ListItemText primary='Eliminar' />
                </ListItemButton>
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
      )}
    </>
  );
}
