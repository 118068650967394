import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetUltimoAbierto } from "../../api/services/consultas/turnos";
import { hasErrorRedux } from "../../api/services/service";

export const getUltimoAbierto = createAsyncThunk("turnos/ultimoAbierto", async (_, { rejectWithValue }) => {
  try {
    return await GetUltimoAbierto();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

const initialState = {
  turno: {},
  error: {},
  loading: {
    getUltimoAbierto: false,
  },
};

const slice = createSlice({
  name: "turnos",
  initialState,
  reducers: {
    // setTurno: (state, action) => {
    //   state.turno = action.payload;
    // },
    // setFilter: (state, action) => {
    //   state.filter = action.payload;
    // },
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUltimoAbierto.pending, (state) => {
      state.loading.getUltimoAbierto = true;
    });
    builder.addCase(getUltimoAbierto.fulfilled, (state, action) => {
      state.turno = action.payload;
      state.error = {};
      state.loading.getUltimoAbierto = false;
    });
    builder.addCase(getUltimoAbierto.rejected, (state, action) => {
      state.error = action.payload;
      state.turno = {};
      state.loading.getUltimoAbierto = false;
    });
  },
});

export default slice.reducer;
// export const { clearErrors, setTurno } = slice.actions;
export const { clearErrors } = slice.actions;
