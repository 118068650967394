import React, { useEffect } from "react";
import Oidc, { UserManager } from "oidc-client";
import { Loading } from "./loading";
import { useNavigate } from "react-router-dom";
import { Anonymous } from "../../layout";

const signinCallBack = async (navigate) => {
  new UserManager({
    userStore: new Oidc.WebStorageStateStore({ store: window.localStorage }),
  })
    .signinRedirectCallback()
    .then(function () {
      navigate("/");
    })
    .catch(function (e) {
      console.error(e);
    });
};

export const SigninOidc = () => {
  let navigate = useNavigate();

  useEffect(() => {
    signinCallBack(navigate);
  });

  return (
    <Anonymous>
      <Loading />
    </Anonymous>
  );
};
