import {
  Delete,
  GetAll,
  GetById,
  Insert,
  SelectClaseTipoPago,
  SelectFormaPagoAdmin,
  Update,
} from "../../api/services/catalogos/tiposPagos";
import { getOnlyActiveValues } from "@paul-igas/igas-react-hooks";
import { hasErrorRedux } from "../../api/services/service";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getAll = createAsyncThunk("servicios/all", async (params, { rejectWithValue }) => {
  try {
    return await GetAll(getOnlyActiveValues(params));
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const insert = createAsyncThunk("servicios/insert", async (params, { rejectWithValue }) => {
  try {
    return await Insert(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getById = createAsyncThunk("servicios", async (params, { rejectWithValue }) => {
  try {
    return await GetById(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const update = createAsyncThunk("servicios/update", async (params, { rejectWithValue }) => {
  try {
    return await Update(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const remove = createAsyncThunk("servicios/remove", async (params, { rejectWithValue }) => {
  try {
    return await Delete(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const selectClaseTPList = createAsyncThunk("servicios/selectClaseList", async (params, { rejectWithValue }) => {
  try {
    return await SelectClaseTipoPago();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const selectFormaPagoAdminList = createAsyncThunk(
  "servicios/selectFormaPagoAdmin",
  async (_, { rejectWithValue }) => {
    try {
      return await SelectFormaPagoAdmin();
    } catch (error) {
      return rejectWithValue(hasErrorRedux(error));
    }
  }
);

export const initialState = {
  tiposPagos: [],
  tipoPago: {},
  tipoPagoIdSelected: "",
  error: {},
  filter: {
    activo: { state: true, value: true, label: "Activo" },
  },
  loading: {
    getAll: false,
    getById: false,
    insert: false,
    update: false,
    delete: false,
    selectCTPList: false,
    selectFPAList: false,
  },
};

const slice = createSlice({
  name: "tiposPagos",
  initialState,
  reducers: {
    setTipoPagoIdSelected: (state, action) => {
      state.tipoPagoIdSelected = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state) => {
      state.loading.getAll = true;
    });
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.error = {};
      state.loading.getAll = false;
      state.tiposPagos = action.payload;
    });
    builder.addCase(getAll.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getAll = false;
      state.tiposPagos = [];
    });
    builder.addCase(getById.pending, (state) => {
      state.loading.getById = true;
    });
    builder.addCase(getById.fulfilled, (state, action) => {
      state.error = {};
      state.tipoPago = action.payload;
      state.loading.getById = false;
    });
    builder.addCase(getById.rejected, (state, action) => {
      state.error = action.payload;
      state.tipoPago = {};
      state.loading.getById = false;
    });
    builder.addCase(insert.pending, (state) => {
      state.loading.insert = true;
    });
    builder.addCase(insert.fulfilled, (state) => {
      state.error = {};
      state.loading.insert = false;
    });
    builder.addCase(insert.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.insert = false;
    });
    builder.addCase(update.pending, (state) => {
      state.loading.update = true;
    });
    builder.addCase(update.fulfilled, (state) => {
      state.error = {};
      state.loading.update = false;
    });
    builder.addCase(update.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.update = false;
    });
    builder.addCase(remove.pending, (state) => {
      state.loading.delete = true;
    });
    builder.addCase(remove.fulfilled, (state) => {
      state.error = {};
      state.loading.delete = false;
    });
    builder.addCase(remove.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.delete = false;
    });
    builder.addCase(selectClaseTPList.pending, (state) => {
      state.loading.selectCTPList = true;
    });
    builder.addCase(selectClaseTPList.fulfilled, (state) => {
      state.error = {};
      state.loading.selectCTPList = false;
    });
    builder.addCase(selectClaseTPList.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.selectCTPList = false;
    });
    builder.addCase(selectFormaPagoAdminList.pending, (state) => {
      state.loading.selectFPAList = true;
    });
    builder.addCase(selectFormaPagoAdminList.fulfilled, (state) => {
      state.error = {};
      state.loading.selectFPAList = false;
    });
    builder.addCase(selectFormaPagoAdminList.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.selectFPAList = false;
    });
  },
});

export default slice.reducer;

export const { setTipoPagoIdSelected, setFilter, clearErrors } = slice.actions;
