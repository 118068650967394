import api from "../service";

const _controller = "configuracionEstacion/servicios";

export async function GetConfigurations(idModulo) {
  const params = { idModulo: idModulo };
  const res = await api.get(`${_controller}`, { params });
  return res.data;
}

export async function Update(params) {
  const res = await api.put(`${_controller}`, params);
  return res.data;
}

export default {
  Update,
};
