import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Grid, Skeleton } from "@mui/material";
import { IGasSwitchField, IGasSliderField, IGasNumberField, IGasSelectField } from "@paul-igas/igas-react-components";
import { getAll } from "../../../store/catalogos/tiposPagos";

const MARKS = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
];

const MARKSTURNOSESTACION = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
];

const FILTER = {
  activo: { state: true, value: true },
};

export const ConfLiquidaciones = ({ open, errorsMgr, data = [], initialize = false, setInitialize = () => {}, pagos = [] }) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.tiposPagos);
  // const [pagos, setPagos] = useState([]);

  const getValueAttr = (attr, id) => {
    switch (attr) {
      case "desc":
        return data?.find((x) => x.idVariable === id)?.descripcion;
      case "name":
        return data?.find((x) => x.idVariable === id)?.nombreVariable;
      default:
        return data?.find((x) => x.idVariable === id)?.valor;
    }
  };

  const getErrorMsg = (id) => {
    let msg = "";
    if (errorsMgr === null || errorsMgr === undefined) return msg;

    Object.entries(errorsMgr)?.map((p) => {
      if (p[0] === getValueAttr("name", id)) msg = p[1];
    });

    return msg;
  };

  // const initiate = ({ payload }) => {
  //   setPagos(payload);
  //   setInitialize(false);
  // };

  // const initSection = () => {
  //   if (open && initialize) {
  //     dispatch(getAll(FILTER)).then(initiate);
  //   }
  // };

  // useEffect(initSection, [open]);

  if (data.length <= 0) return null;

  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={6}>
        <IGasNumberField
          autoFocus
          label='Volumen máximo totalizador'
          name={getValueAttr("name", 3)}
          defaultValue={getValueAttr("val", 3)}
          error={getErrorMsg(3)}
          maxLength={8}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <IGasNumberField
          label='Volumen máximo de diferencia'
          name={getValueAttr("name", 4)}
          defaultValue={getValueAttr("val", 4)}
          error={getErrorMsg(4)}
          maxLength={3}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        {loading.getAll && <Skeleton animation='wave' />}
        {!loading.getAll && (
          <IGasSelectField
            label='Tipo de pago efectivo'
            name={getValueAttr("name", 5)}
            defaultValue={getValueAttr("val", 5)}
            error={getErrorMsg(5)}
          >
            <MenuItem value='0' disabled>
              <em>Seleccione un tipo de pago</em>
            </MenuItem>
            {pagos?.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.descripcion}
              </MenuItem>
            ))}
          </IGasSelectField>
        )}
      </Grid>

      <Grid item xs={12} md={6}>
        {loading.getAll && <Skeleton animation='wave' />}
        {!loading.getAll && (
          <IGasSelectField
            label='Tipo de pago faltante/sobrante'
            name={getValueAttr("name", 6)}
            defaultValue={getValueAttr("val", 6)}
            error={getErrorMsg(6)}
          >
            <MenuItem value='0' disabled>
              <em>Seleccione un tipo de pago</em>
            </MenuItem>
            {pagos?.map((i) => (
              <MenuItem key={i.id} value={i.id}>
                {i.descripcion}
              </MenuItem>
            ))}
          </IGasSelectField>
        )}
      </Grid>

      <Grid item xs={12} md={6}>
        <IGasSliderField
          label='Días para reabrir turnos en liquidaciones'
          name={getValueAttr("name", 1)}
          defaultValue={getValueAttr("val", 1)}
          step={1}
          minVal={MARKS[0].value}
          maxVal={MARKS[MARKS.length - 1].value}
          marks={MARKS}
          markLabelSize={10}
        />
      </Grid>

      <Grid item xs={12} md={6} sx={{ mt: 2 }}>
        <IGasSwitchField
          name={getValueAttr("name", 2)}
          defaultValue={getValueAttr("val", 2)}
          label='Maneja denominaciones'
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <IGasSwitchField
          name={getValueAttr("name", 7)}
          defaultValue={getValueAttr("val", 7)}
          label='Maneja consignación'
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <IGasSwitchField
          name={getValueAttr("name", 8)}
          defaultValue={getValueAttr("val", 8)}
          label='Validar diferencia lectura'
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <IGasSliderField
          label='Máximo de turnos de liquidación por día'
          name={getValueAttr("name", 9)}
          defaultValue={getValueAttr("val", 9)}
          step={1}
          minVal={MARKSTURNOSESTACION[0].value}
          maxVal={MARKSTURNOSESTACION[MARKSTURNOSESTACION.length - 1].value}
          marks={MARKSTURNOSESTACION}
          markLabelSize={10}
        />
      </Grid>
    </Grid>
  );
};
