import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import {
  DangerMessage,
  SuccessMessage,
  WarningMessage,
  LoadingPanel,
  IGasForm,
} from "@paul-igas/igas-react-components";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Tabs, Tab, Paper, Grid } from "@mui/material";
import { clearErrors, selectAll, update } from "../../store/herramientas/configuracionEstacion";
import { setConfigurations } from "../../store/aplicacion";
import { useModal } from "@paul-igas/igas-react-hooks";
import { hasDispatchError } from "../../api/services/service";
import { ConfLiquidaciones } from "../../components/herramientas/configuracion/confLiquidaciones";
import { ConfAdministracion } from "../../components/herramientas/configuracion/confAdministracion";
import { updateLogoEstacion, getLogoEstacion } from "../../store/herramientas/suscripcion";
import { getAll } from "../../store/catalogos/tiposPagos";

const FILTER = {
  activo: { state: true, value: true },
};

export const ConfiguracionEstacion = () => {
  const dispatch = useDispatch();
  const { loading, error, configuracionesEstacion } = useSelector((state) => state.configuracionEstacion);
  const [open, setOpen] = useState(false);
  const [logo, setLogo] = useState(null); 
  const [selectedImage, setSelectedImage] = useState(null); 
  const [errors, setErrors] = useState({});
  const { selectedPage, handleChangePage } = TabsHandler();
  const [sleeping, setSleeping] = useState(false);
  const [loadPagos, setLoadPagos] = useState(true);
  const [pagos, setPagos] = useState([]);
  const [logoLoading, setLogoLoading] = useState(false);
  const navigate = useNavigate();
  const disabled = loading.all || loading.update || sleeping;

  const successMsg = useModal(false);
  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);

  const wait = (ms) => new Promise((r) => setTimeout(r, ms));

  const transformaForm = (formArray) => {
    return formArray.reduce((acc, item) => {
      acc[item.nombreVariable] = item.valor;
      return acc;
    }, {});
  };

  const validaForm = (formArray) => {
    const form = transformaForm(formArray);
    const newErrors = {};
    newErrors.VolumenMaximoTotalizador =
      form.VolumenMaximoTotalizador?.toString() === "" ? "'Volumen máximo totalizador' es requerido." : "";
    newErrors.VolumenMaximoDiferenciaEnLiq =
      form.VolumenMaximoDiferenciaEnLiq?.toString() === "" ? "'Volumen máximo de diferencia' es requerido." : "";
    newErrors.TipoPagoEfectivo =
      form.TipoPagoEfectivo?.toString() === "0" ? "'Tipo de pago efectivo' es requerido." : "";
    newErrors.FaltanteSobrante =
      form.FaltanteSobrante?.toString() === "0" ? "'Tipo de pago faltante/sobrante' es requerido." : "";

    setErrors(newErrors);

    return Object.values(newErrors).every((x) => x === "");
  };

  const handleLoadPagos = (newValue) => {
    setLoadPagos(newValue);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    setOpen(false);
    navigate("/");
  };

  const sleep = async () => {
    setSleeping(true);
    await wait(1500);
    setSleeping(false);
  };

  const handleSubmit = (formData) => {
    let tmp = configuracionesEstacion.map((i) => ({ ...i, valor: formData[i.nombreVariable] ?? i.valor }));
    if (validaForm(tmp)) {
      dispatch(setConfigurations(tmp));
      dispatch(update(tmp))
        .then(hasDispatchError)
        .then(successMsg.open)
        .then(sleep)
        .then(handleClose)
        .catch(() => {
          setSleeping(false);
        });
      if (selectedImage) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const byteArray = new Uint8Array(reader.result);
          dispatch(updateLogoEstacion({ bytes: byteArray }))
            .then(hasDispatchError)
            .catch(() => {});
        };
        reader.readAsArrayBuffer(selectedImage);
      } else if (logo) {
        dispatch(updateLogoEstacion({ bytes: logo }))
          .then(hasDispatchError)
          .catch(() => {});
      }
    }
  };

  const arrayBufferToBase64 = (buffer) => {
    let binary = "";
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error?.status === 409 && warningMsg.open();
      error?.status === 500 && dangerMsg.open();
    }
  };

  const init = () => {
    if (!open) setOpen(true);
    if (open) {
      setLoadPagos(true);
      dispatch(clearErrors());
      dispatch(selectAll(0)).catch(handleClose);
      dispatch(getLogoEstacion())
        .then(setLogoLoading(true))
        .then(hasDispatchError)
        .then((payload) => {
          setLogo(payload.payload);
        })
        .then(setLogoLoading(false))
        .catch(() => {});
      dispatch(getAll(FILTER))
        .then(initPagos)
        .then(hasDispatchError)
        .catch(() => {});
    }
  };

  const initPagos = ({ payload }) => {
    setPagos(payload);
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  useEffect(init, [open]);
  useEffect(handleError, [error]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown maxWidth='sm' fullWidth scroll='paper'>
        <DialogTitle>Configuración de estación</DialogTitle>
        <DialogContent>
          {(loading.all || logoLoading) && <LoadingPanel title='Consultando...' />}
          {(!loading.all && !logoLoading) && (
            <div style={{ minHeight: "350px", maxHeight: "350px" }}>
              <Tabs
                variant='scrollable'
                value={selectedPage}
                onChange={handleChangePage}
                TabIndicatorProps={{ style: { display: "none" } }}
              >
                <Tab
                  label='Administración'
                  {...a11yProps(0)}
                  sx={{
                    ["&.Mui-selected"]: {
                      backgroundColor: "whitesmoke",
                    },
                  }}
                />
                <Tab
                  label='Liquidaciones'
                  {...a11yProps(1)}
                  sx={{ ["&.Mui-selected"]: { backgroundColor: "whitesmoke" } }}
                />
              </Tabs>

              <IGasForm id='configuracion-estacion-dlg' onSubmit={handleSubmit}>
                <TabPanel value={selectedPage} index={0}>
                  <ConfAdministracion
                    open={open}
                    errorsMgr={errors}
                    data={configuracionesEstacion.filter((x) => x.idModulo === 1)}
                    logo={logo}
                    asignaLogo={(x) => {setLogo(x)}}
                    disabled={disabled}
                  />
                </TabPanel>

                <TabPanel value={selectedPage} index={1}>
                  <ConfLiquidaciones
                    open={open}
                    errorsMgr={errors}
                    data={configuracionesEstacion.filter((x) => x.idModulo === 2)}
                    initialize={loadPagos}
                    setInitialize={handleLoadPagos}
                    pagos={pagos}
                  />
                </TabPanel>
              </IGasForm>
            </div>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color='secondary' disabled={disabled}>
            Cancelar
          </Button>

          <Button
            type='submit'
            color='secondary'
            form={"configuracion-estacion-dlg"}
            disabled={disabled || configuracionesEstacion.length <= 0}
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>

      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se modificó correctamente.' />
      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
    </>
  );
};

const TabsHandler = () => {
  const [selectedPage, setSelectedPage] = useState(0);

  const handleChange = (event, newPage) => {
    setSelectedPage(newPage);
  };

  return {
    selectedPage,
    handleChangePage: handleChange,
  };
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`} {...other}>
      {value === index && <Paper sx={{ p: 2, backgroundColor: "whitesmoke", minHeight: "310px" }}>{children}</Paper>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `tab-${index}`,
    "aria-controls": `tabpanel-${index}`,
  };
}
