import React from 'react';
import { Link } from "@mui/material";
import { useSelector } from "react-redux"
import { logout } from '../../api/authService';

export const Forbidden = ({activeLogout = false}) => {
    const {profile} = useSelector((state) => state.aplicacion);

    function handleLogout(){
        logout();
    }

    return(
        <div style={{
            textAlign: 'center',
            paddingTop: 40
        }}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="144px" height="144px"><linearGradient id="GCWVriy4rQhfclYQVzRmda" x1="9.812" x2="38.361" y1="9.812" y2="38.361" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#f44f5a" /><stop offset=".443" stopColor="#ee3d4a" /><stop offset="1" stopColor="#e52030" /></linearGradient><path fill="url(#GCWVriy4rQhfclYQVzRmda)" d="M24,4C12.955,4,4,12.955,4,24s8.955,20,20,20s20-8.955,20-20C44,12.955,35.045,4,24,4z M24,38	c-7.732,0-14-6.268-14-14s6.268-14,14-14s14,6.268,14,14S31.732,38,24,38z" /><linearGradient id="GCWVriy4rQhfclYQVzRmdb" x1="6.821" x2="41.08" y1="6.321" y2="40.58" gradientTransform="translate(-.146 .354)" gradientUnits="userSpaceOnUse"><stop offset="0" stopColor="#f44f5a" /><stop offset=".443" stopColor="#ee3d4a" /><stop offset="1" stopColor="#e52030" /></linearGradient><polygon fill="url(#GCWVriy4rQhfclYQVzRmdb)" points="13.371,38.871 9.129,34.629 34.629,9.129 38.871,13.371" /></svg>
            <div style={{
                margin: 'auto',
                maxWidth:501
            }}>
                <h1>Acceso Denegado</h1>
                <p>Usuario {profile.name} no tiene acceso a esta página o recurso, para más información póngase en contacto con su administrador de sistema.</p>
            {activeLogout &&
            <Link
                component="button"
                variant="body2"
                style={{color:"#039be5"}}
                onClick={handleLogout}>
                    Cerrar Sesión
                </Link>
            }
            </div>
        </div>
    );
}