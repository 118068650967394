import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hasErrorRedux } from "../../api/services/service";
import { GetAll, Insert, Update, Delete, GetById } from "../../api/services/operaciones/lecturasIniciales";

export const selectAll = createAsyncThunk("lecturasIniciales/getAll", async (_, { rejectWithValue }) => {
  try {
    return await GetAll();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const insert = createAsyncThunk("lecturasIniciales/insert", async (params, { rejectWithValue }) => {
  try {
    return await Insert(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getbyId = createAsyncThunk("lecturasIniciales/getById", async (params, { rejectWithValue }) => {
  try {
    return await GetById(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const update = createAsyncThunk("lecturasIniciales/update", async (params, { rejectWithValue }) => {
  try {
    return await Update(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const remove = createAsyncThunk("lecturasIniciales/remove", async (params, { rejectWithValue }) => {
  try {
    return await Delete(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

const initialState = {
  lecturasIniciales: [],
  lecturaInicial: {},
  error: {},
  lecturaInicialIdSelected: "",
  loading: {
    getAll: false,
    getbyId: false,
    insert: false,
    update: false,
    delete: false,
  },
};

const slice = createSlice({
  name: "lecturasIniciales",
  initialState,
  reducers: {
    setLecturaInicialIdSelected: (state, action) => {
      state.lecturaInicialIdSelected = action.payload;
    },
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    // SelectAll
    builder.addCase(selectAll.pending, (state) => {
      state.loading.getAll = true;
    });
    builder.addCase(selectAll.fulfilled, (state, action) => {
      state.lecturasIniciales = action.payload;
      state.error = {};
      state.loading.getAll = false;
    });
    builder.addCase(selectAll.rejected, (state, action) => {
      state.error = action.payload;
      state.lecturasIniciales = [];
      state.loading.getAll = false;
    });
    builder.addCase(getbyId.pending, (state) => {
      state.loading.getbyId = true;
    });
    builder.addCase(getbyId.fulfilled, (state, action) => {
      state.lecturaInicial = action.payload;
      state.error = {};
      state.loading.getbyId = false;
    });
    builder.addCase(getbyId.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getbyId = false;
      state.lecturaInicial = {};
    });
    builder.addCase(insert.pending, (state) => {
      state.loading.insert = true;
    });
    builder.addCase(insert.fulfilled, (state, action) => {
      state.error = {};
      state.loading.insert = false;
      state.lecturaInicial = action.payload;
    });
    builder.addCase(insert.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.insert = false;
    });
    builder.addCase(update.pending, (state) => {
      state.loading.update = true;
    });
    builder.addCase(update.fulfilled, (state) => {
      state.error = {};
      state.loading.update = false;
    });
    builder.addCase(update.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.update = false;
    });
    builder.addCase(remove.pending, (state) => {
      state.loading.delete = true;
    });
    builder.addCase(remove.fulfilled, (state) => {
      state.error = {};
      state.loading.delete = false;
    });
    builder.addCase(remove.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.delete = false;
    });
  },
});

export default slice.reducer;
export const { setLecturaInicialIdSelected, clearErrors } = slice.actions;
