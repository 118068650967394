import { Backspace, Edit, MoreVert, Pageview } from "@mui/icons-material";
import { Button, ListItemIcon, ListItemText, Menu, MenuItem } from "@mui/material";
import { useState } from "react";

export function OptionTableRow({ data, onEdit, onDelete, onSelect, onDetail }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const handleOpen = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null);

  const handleEdit = () => {
    handleClose();
    onSelect(data.id);
    onEdit();
  };

  const handleDelete = () => {
    setAnchorEl();
    onSelect(data.id);
    onDelete();
  };

  const handleDetail = () => {
    handleClose();
    onSelect(data.id);
    onDetail();
  };

  return (
    <>
      <Button size='small' disableElevation style={{ minWidth: 0 }} onClick={handleOpen}>
        <MoreVert fontSize='small' color='action' />
      </Button>
      {Boolean(anchorEl) && (
        <Menu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
          {/* <MenuItem dense onClick={handleDetail}>
            <ListItemIcon>
              <Pageview fontSize='small' />
            </ListItemIcon>
            <ListItemText primary='Ver detalle' />
          </MenuItem> */}
          <MenuItem dense={true} onClick={handleEdit}>
            <ListItemIcon>
              <Edit fontSize='small' />
            </ListItemIcon>
            <ListItemText primary='Editar' />
          </MenuItem>
          <MenuItem dense={true} onClick={handleDelete}>
            <ListItemIcon>
              <Backspace fontSize='small' />
            </ListItemIcon>
            <ListItemText primary='Eliminar' />
          </MenuItem>
        </Menu>
      )}
    </>
  );
}

export default OptionTableRow;
