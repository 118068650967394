import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { SuccessMessage } from "@paul-igas/igas-react-components";
import { formatDateTimeMX, useModal, zeroPad } from "@paul-igas/igas-react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { getbyId, remove } from "../../../store/operaciones/lecturasIniciales";
import { boldText } from "../../../helpers/utils";
import { useEffect } from "react";

export function DeleteDialog({ open, onClose, postSubmit }) {
  const dispatch = useDispatch();
  const { loading, lecturaInicialIdSelected, lecturaInicial } = useSelector((state) => state.lecturasIniciales);

  const successMsg = useModal(false);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const onSubmit = () => {
    dispatch(remove(lecturaInicialIdSelected))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {});
  };

  const initDialog = () => {
    if (open) {
      dispatch(getbyId(lecturaInicialIdSelected))
        .then(hasDispatchError)
        .catch(() => {});
    }
  };

  useEffect(initDialog, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth='xs'>
        <DialogTitle>Eliminar lectura inicial</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Desea eliminar la lectura inicial del turno {boldText(zeroPad(lecturaInicial.noTurno, 2))} del día{" "}
            {boldText(formatDateTimeMX(lecturaInicial?.fechaTurno ?? new Date()))}?
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button color='secondary' disabled={loading.delete} onClick={onClose}>
            Cancelar
          </Button>
          <Button color='secondary' disabled={loading.delete} onClick={onSubmit}>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se eliminó correctamente.' />
    </>
  );
}

export default DeleteDialog;
