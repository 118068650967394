import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { hasErrorRedux } from "../../api/services/service";
import { GetAll } from "../../api/services/catalogos/estaciones";

export const getAll = createAsyncThunk('estaciones/selectAll',
    async (_, { rejectWithValue }) => {
        try {
            return await GetAll();
        } catch (error) {
            return rejectWithValue(hasErrorRedux(error));
        }
    });

const initialState = {
    station: {},
    stations: [],
    error: {},
    loading: false,
};

const slice = createSlice({
    name: 'estaciones',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getAll.pending, (state) => {
            state.loading = true;
        })
        builder.addCase(getAll.fulfilled, (state, action) => {
            state.error = {};
            state.loading = false;
            state.stations = action.payload;
        })
        builder.addCase(getAll.rejected, (state, action) => {
            state.error = action.payload;
            state.loading = false;
            state.stations = [];
        })
    }
})

export default slice.reducer;

