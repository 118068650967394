import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectFormaPagoAdminList } from "../../../../store/catalogos/tiposPagos";
import { hasDispatchError } from "../../../../api/services/service";
import { Grid, MenuItem, Skeleton } from "@mui/material";
import { IGasSelectField } from "@paul-igas/igas-react-components";

export const ListaFormaPago = ({ open = false, errorMsg = "", value = "0" }) => {
  const { formasPago, loading } = useFormaPago(open);

  return (
    <>
      {loading.selectFPAList && <Skeleton animation='wave' />}
      {!loading.selectFPAList && (
        <Grid container spacing={3} paddingTop={2}>
          <Grid item xs={12}>
            <IGasSelectField label='Forma de pago' name='idFormaPago' defaultValue={value} error={errorMsg}>
              <MenuItem value='0' disabled>
                <em>Seleccione una forma de pago</em>
              </MenuItem>
              {formasPago.map((i) => (
                <MenuItem key={i.id} value={i.id}>
                  {i.descripcion}
                </MenuItem>
              ))}
            </IGasSelectField>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ListaFormaPago;

const useFormaPago = (open) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.tiposPagos);
  const [formasPago, setFormasPago] = useState([]);

  const initFormasPago = () => {
    if (open === true) {
      dispatch(selectFormaPagoAdminList())
        .then(hasDispatchError)
        .then(({ payload }) => setFormasPago(payload))
        .catch(() => {});
    }
  };

  useEffect(initFormasPago, [open]);

  return {
    formasPago,
    loading,
  };
};
