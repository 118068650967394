import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import { useModal } from "@paul-igas/igas-react-hooks";
import { DangerMessage, IGasForm, IGasDecimalField, SuccessMessage } from "@paul-igas/igas-react-components";

export const LecturasEditDialog = ({ open, onClose, postSubmit, data }) => {
  const [errors, setErrors] = useState();
  const successMsg = useModal(false);
  const dangerMsg = useModal(false);
  const validaForm = (form) => {
    const newErrors = {};
    newErrors.lecturaInicial = form.lecturaInicial.toString() === "" ? "'Lectura inicial' es requerido." : "";
    setErrors(newErrors);
    return Object.values(newErrors).every((x) => x === "");
  };
  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };
  const initDialog = () => {
    if (open) {
      setErrors({});
    }
  };

  const handleEdit = (comb) => {
    if (validaForm(comb)) {
      const totalizadorVolumen = parseFloat(comb.lecturaInicial);
      const newData = {
        ...data,
        totalizadorVolumen,
      };
      onClose();
      successMsg.open();
      postSubmit(newData);
    }
  };

  useEffect(initDialog, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown maxWidth='xs' scroll='paper'>
        <DialogTitle>{`Disp: ${data?.dispensario}`}</DialogTitle>
        <DialogContent dividers>
          <IGasForm id='lecturas-edit-dlg' onSubmit={handleEdit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <IGasDecimalField
                  autoFocus
                  label='Lectura inicial'
                  name='lecturaInicial'
                  defaultValue={Number(data?.totalizadorVolumen).toFixed(3)}
                  error={errors?.lecturaInicial}
                  maxLength={11}
                  decimalScale={3}
                />
              </Grid>
            </Grid>
          </IGasForm>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation disabled={false} onClick={handleClose}>
            Cancelar
          </Button>
          <Button color='secondary' disableElevation disabled={false} type='submit' form='lecturas-edit-dlg'>
            Modificar
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se modificó correctamente.' />
      <DangerMessage
        open={dangerMsg.isShowing}
        onClose={dangerMsg.close}
        text='Volumen de venta no puede quedar en negativo.'
      />
    </>
  );
};
