import { getStation } from "../../authService";
import api from "../service";

const _controller = "suscripcion/servicios";

export async function UpdateLogoEstacion(params) {
  let _p = {
    idEstacion: await getStation(),
    ...params,
  };
  const res = await api.post(`${_controller}/PostLogoEstacion`, _p);
  return res.data;
}

export async function GetLogoEstacion() {
  let params = { idEstacion: await getStation() };
  const res = await api.get(`${_controller}/GetLogoEstacion`, { params });
  return res.data;
}

export default {
  UpdateLogoEstacion,
  GetLogoEstacion,
};
