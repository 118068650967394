import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { clearErrors, getAll } from "../../store/catalogos/combustibles";
import {
  Catalogo,
  ColumnAlign,
  ColumnPriority,
  ColumnType,
  DangerMessage,
  WarningMessage,
} from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";

const TABLE_DEFAULT = {
  columns: [
    { text: "Nombre", name: "nombreCombustible", orderDefault: true },
    {
      text: "Precio unitario",
      name: "precioUnitario",
      colPriority: ColumnPriority.Normal,
      colAlign: ColumnAlign.Right,
      colType: ColumnType.Money,
    },
    { text: "Clave identificación", name: "claveIdentificacion" },
  ],
};

export function Combustibles() {
  const dispatch = useDispatch();
  const { loading, combustibles, error } = useSelector((state) => state.combustibles);
  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);
  const handleRefresh = () => {
    dispatch(getAll());
  };

  const handleError = () => {
    if (Boolean(error?.status)) {
      error?.status === 409 && warningMsg.open();
      error?.status === 500 && dangerMsg.open();
    }
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  useEffect(handleError, [error]);

  return (
    <>
      <Catalogo
        title='Combustibles'
        table={TABLE_DEFAULT}
        data={combustibles}
        loading={loading.getAll}
        onRefresh={handleRefresh}
      />

      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error.message} />
      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error.message} />
    </>
  );
}
