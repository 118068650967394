import { useEffect, useState } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Skeleton } from "@mui/material";
import { SuccessMessage } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { getById, remove } from "../../../store/catalogos/tiposPagos";
import { boldText } from "../../../helpers/utils";

export function DeleteDialog({ open, onClose, postSubmit }) {
  const dispatch = useDispatch();
  const { loading, tipoPago, tipoPagoIdSelected } = useSelector((state) => state.tiposPagos);
  const buttonsLoading = loading.delete || loading.getById;
  const { handleSubmit } = useForm();
  const successMsg = useModal(false);

  const onSubmit = () => {
    dispatch(remove(tipoPagoIdSelected))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {});
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const init = () => {
    if (open === true) {
      dispatch(getById(tipoPagoIdSelected)).then(hasDispatchError).catch(onClose);
    }
  };

  useEffect(init, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth='xs'>
        <DialogTitle>Eliminar tipo de pago</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {loading.getById && <Skeleton animation='wave' width='100%' />}
            {!loading.getById && <>¿Desea eliminar el tipo de pago {boldText(tipoPago.descripcion)}?</>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disabled={buttonsLoading} onClick={onClose}>
            Cancelar
          </Button>
          <Button onClick={handleSubmit(onSubmit)} color='secondary' disabled={buttonsLoading} autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se eliminó correctamente.' />
    </>
  );
}
