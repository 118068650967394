import api from "../service";

const route = "bitacora/servicios";

export async function GetLogs(params) {
  const res = await api.get(`${route}/selectAll`, { params });
  return res.data;
}

export async function GetTypes() {
  const res = await api.get(`${route}/selectTypes`);
  return res.data;
}

export default {
  GetLogs,
  GetTypes
};
