import api from "./../service";

const _controller = "lecturainicial/servicios";

export async function GetAll() {
  const res = await api.get(`${_controller}/selectAll`);
  return res.data;
}

export async function Insert(params) {
  const res = await api.post(`${_controller}`, params);
  return res.data;
}

export async function GetById(id) {
  const params = { id: id };
  const res = await api.get(`${_controller}`, { params });
  return res.data;
}

export async function Update(params) {
  const res = await api.put(`${_controller}`, params);
  return res.data;
}

export async function Delete(id) {
  const params = { id: id };
  const res = await api.delete(`${_controller}`, { params });
  return res.data;
}

export default {
  GetAll,
  Insert,
  GetById,
  Update,
  Delete,
};
