import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GetAll } from "../../api/services/catalogos/productosDiversos";
import { hasErrorRedux } from "../../api/services/service";

export const getAll = createAsyncThunk('servicios/select',
    async (_, { rejectWithValue }) => {
        try {
            return await GetAll();
        } catch (error) {
            return rejectWithValue(hasErrorRedux(error));
        }
    });

const initialState = {
    error: {},
    productosDiversos: [],
    loading: {
        getAll: false
    }
};

const slice = createSlice({
    name: 'productosDiversos',
    initialState,
    reducers: {
        clearErrors: (state) => { state.error = {} },
    },
    extraReducers: (builder) => {
        builder.addCase(getAll.pending, (state) => {
            state.loading.getAll = true;
        })
        builder.addCase(getAll.fulfilled, (state, action) => {
            state.error = {};
            state.loading.getAll = false;
            state.productosDiversos = action.payload;
        })
        builder.addCase(getAll.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.getAll = false;
            state.productosDiversos = [];
        })
    }
})

export default slice.reducer;

export const { clearErrors } = slice.actions