import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hasErrorRedux } from "../../api/services/service";
import { GetAll } from "../../api/services/catalogos/combustibles";

export const getAll = createAsyncThunk('servicios/select',
    async (_, { rejectWithValue }) => {
        try {
            return await GetAll();
        } catch (error) {
            return rejectWithValue(hasErrorRedux(error));
        }
    });

export const initialState = {
    error: {},
    combustibles: [],
    loading: {
        getAll: false
    }
};

const slice = createSlice({
    name: 'combustibles',
    initialState,
    reducers: {
        clearErrors: (state) => { state.error = {} },
    }
    ,
    extraReducers: (builder) => {
        builder.addCase(getAll.pending, (state) => {
            state.loading.getAll = true;
        })
        builder.addCase(getAll.fulfilled, (state, action) => {
            state.error = {};
            state.loading.getAll = false;
            state.combustibles = action.payload;
        })
        builder.addCase(getAll.rejected, (state, action) => {
            state.error = action.payload;
            state.loading.getAll = false;
            state.combustibles = [];
        })
    }
});


export default slice.reducer;

export const { clearErrors } = slice.actions