import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  Paper,
  TextField,
} from "@mui/material";
import {
  ColumnAlign,
  ColumnType,
  DetailAlign,
  DetailDataType,
  DetailInfo,
  LoadingPanel,
  SimpleTable,
  SuccessMessage,
} from "@paul-igas/igas-react-components";
import { useModal, zeroPad } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { getbyId, update } from "../../../store/operaciones/lecturasIniciales";
import { hasDispatchError } from "../../../api/services/service";
import { OptionTableRow } from "./optionTableRow";
import { Controller, useForm } from "react-hook-form";
import { LecturasEditDialog } from "./lecturasEditDialog";

const TABLE_DEFAULT = {
  columns: [
    { text: "Dispensario", name: "dispensario", orderDefault: true },
    {
      text: "Lect. inicial",
      name: "totalizadorVolumen",
      colType: ColumnType.Decimal,
      digitos: 3,
      colAlign: ColumnAlign.Right,
    },
    { text: "", name: "action", colAlign: ColumnAlign.Center, options: true, icon: true },
  ],
};

const idForm = "form-lecturainicial-update";

export function EditDialog({ open, onClose, postSubmit }) {
  const dispatch = useDispatch();
  const {
    loading,
    lecturaInicial,
    mangueras,
    lecturasEditDialog,
    mangueraEditar,
    openLecturasEditDialog,
    onSubmitLecturasEditDialog,
  } = useModalFetch(open);
  const { handleSubmit, reset, control } = useForm();

  const [errors, setErrors] = useState({});
  const successMsg = useModal(false);

  const validaForm = (form) => {
    let newErrors = [];
    newErrors.tecnico = form.tecnico?.toString() === "" ? "'Proveedor técnico' es requerido" : "";
    newErrors.evento = form.evento?.toString() === "" ? "'Evento' es requerido" : "";

    setErrors({ ...newErrors });

    return Object.values(newErrors).every((x) => x === "");
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const onSave = (data) => {
    if (validaForm(data)) {
      let lectIni = { ...lecturaInicial, tecnico: data.tecnico, evento: data.evento, detalle: mangueras };

      dispatch(update(lectIni))
        .then(hasDispatchError)
        .then(successMsg.open)
        .then(onClose)
        .then(postSubmit)
        .catch(() => {});
    }
  };

  const initDialog = () => {
    if (open) {
      setErrors({});
      reset(lecturaInicial);
    }
  };

  useEffect(initDialog, [open, lecturaInicial]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown fullWidth maxWidth='xs' scroll='paper'>
        {loading.update && <LinearProgress />}
        <DialogTitle onClose={handleClose}>Editar lecturas iniciales</DialogTitle>

        <DialogContent dividers>
          {loading.getbyId && <LoadingPanel />}
          {!loading.getbyId && (
            <>
              <form id={idForm} onSubmit={handleSubmit(onSave)} autoComplete='off'>
                <Grid container spacing={2}>
                  <Grid item xs={6} sm={2}>
                    <DetailInfo
                      title='Turno'
                      description={lecturaInicial?.noTurno ?? ""}
                      dataType={DetailDataType.DataKey}
                      digitos={2}
                      dataAlign={DetailAlign.Center}
                    />
                  </Grid>

                  <Grid item xs={6} sm={3}>
                    <DetailInfo
                      title='Fecha turno'
                      description={lecturaInicial?.fechaTurno ?? new Date()}
                      dataType={DetailDataType.Date}
                      dataAlign={DetailAlign.Center}
                    />
                  </Grid>

                  <Grid item xs={12} sm={7}>
                    <Controller
                      name='tecnico'
                      defaultValue={lecturaInicial?.tecnico ?? ""}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='Proveedor Técnico'
                          autoFocus
                          fullWidth
                          value={field.value}
                          size='small'
                          disabled={loading.update}
                          error={Boolean(errors.tecnico)}
                          helperText={errors.tecnico}
                          variant='standard'
                          onInput={(e) => {
                            e.target.value = e.target.value.toString().slice(0, 100);
                          }}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Controller
                      name='evento'
                      defaultValue={lecturaInicial.evento ?? ""}
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          label='Evento'
                          fullWidth
                          value={field.value}
                          size='small'
                          disabled={loading.update}
                          error={Boolean(errors.evento)}
                          helperText={errors.evento}
                          multiline
                          maxRows={2}
                          onInput={(e) => {
                            e.target.value = e.target.value.toString().slice(0, 200);
                          }}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </form>

              <br />

              <Paper>
                <SimpleTable
                  table={TABLE_DEFAULT}
                  data={mangueras}
                  showPagination={false}
                  loading={loading.getbyId}
                  rowOptions={<OptionTableRow onEditLecturas={openLecturasEditDialog} data={mangueras} />}
                />

                <LecturasEditDialog
                  open={lecturasEditDialog.isShowing}
                  onClose={lecturasEditDialog.close}
                  postSubmit={onSubmitLecturasEditDialog}
                  data={mangueraEditar}
                />
              </Paper>
            </>
          )}
        </DialogContent>
        {!loading.getbyId && (
          <DialogActions>
            <Button color='secondary' disableElevation disabled={loading.update} onClick={handleClose}>
              Cancelar
            </Button>
            <Button type='submit' color='secondary' disableElevation disabled={loading.update} form={idForm}>
              Guardar
            </Button>
          </DialogActions>
        )}
      </Dialog>
      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se guardó correctamente.' />
    </>
  );
}

export default EditDialog;

const useModalFetch = (open) => {
  const dispatch = useDispatch();
  const { loading, lecturaInicialIdSelected } = useSelector((state) => state.lecturasIniciales);
  const [lecturaInicial, setLecturaInicial] = useState({});
  const [mangueras, setMangueras] = useState([]);
  const [mangueraEditar, setMangueraEditar] = useState({});

  const lecturasEditDialog = useModal(false);

  const openLecturasEditDialog = (row) => {
    setMangueraEditar(row);
    lecturasEditDialog.open();
  };

  const onSubmitLecturasEditDialog = (newRow) => {
    let tmp = mangueras.slice();
    const index = tmp.findIndex((p) => p.idManguera === newRow.idManguera);
    tmp[index] = newRow;
    setMangueras(tmp);
  };

  const asignaLecturaInicial = ({ payload }) => {
    setLecturaInicial({ ...payload, detalle: [] });
    setMangueras(
      payload.detalle?.map((d) => ({ ...d, dispensario: zeroPad(d.posCarga, 2) + " - " + d.combustibleDesc }))
    );
  };

  const init = () => {
    if (open) {
      dispatch(getbyId(lecturaInicialIdSelected))
        .then(asignaLecturaInicial)
        .then(hasDispatchError)
        .catch(() => {});
    }
  };

  useEffect(init, [open]);

  return {
    loading,
    lecturaInicial,
    mangueras,
    lecturasEditDialog,
    mangueraEditar,
    openLecturasEditDialog,
    onSubmitLecturasEditDialog,
  };
};
