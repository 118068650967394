import React from 'react';
import { ReactComponent as LogoIGas } from '../../assets/img/logos/IGas150.svg';
import { Grid, Typography, LinearProgress } from '@mui/material';


export const Loading = () => {
  return (
    <Grid
      style={{ height: '50vh' }}
      container
      direction="column"
      justify="center"
      alignItems="center"
    >
      <LogoIGas />
      <LinearProgress style={{width: 200}} />
      <Typography variant="caption">Conectando al servidor</Typography>
    </Grid>
  );
}