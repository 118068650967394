import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
} from "@mui/material";
import {
  IGasForm,
  IGasSwitchField,
  IGasTextField,
  LoadingPanel,
  SuccessMessage,
} from "@paul-igas/igas-react-components";
import { useModal, getModelErrors } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { getById, update } from "../../../store/catalogos/tiposPagos";
import { ListaClasePago, ListaFormaPago } from "./components";

const idForm = "form-tipopago-update-dialog";

export function UpdateDialog({ open, onClose, postSubmit }) {
  const dispatch = useDispatch();
  const successMsg = useModal(false);
  const { loading, tipoPago, tipoPagoIdSelected } = useSelector((state) => state.tiposPagos);
  const { errors } = useModalErrors(open);
  const loadingButtons = loading.update || loading.getById;

  const onSubmit = (data) => {
    const request = { ...data, id: tipoPago.id };
    dispatch(update(request))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {});
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const init = () => {
    if (open === true) {
      dispatch(getById(tipoPagoIdSelected)).then(hasDispatchError).catch(onClose);
    }
  };

  useEffect(init, [open]);

  return (
    <>
      <Dialog maxWidth='xs' fullWidth open={open} onClose={handleClose} disableEscapeKeyDown>
        {loading.update && <LinearProgress />}
        <DialogTitle onClose={onClose}>Editar tipo de pago</DialogTitle>
        <DialogContent dividers>
          {loading.getById && <LoadingPanel />}
          {!loading.getById > 0 && (
            <IGasForm id={idForm} onSubmit={onSubmit}>
              <Grid container spacing={2} alignContent='space-between'>
                <Grid item xs={12}>
                  <IGasTextField
                    label='Descripción'
                    name='descripcion'
                    defaultValue={tipoPago.descripcion}
                    disabled={loading.update}
                    error={errors?.descripcion}
                    maxLength={50}
                    autoFocus
                  />
                </Grid>
              </Grid>
              
              <ListaClasePago open={open} errorMsg={errors?.clase ?? ""} value={tipoPago?.clase ?? "0"} />
              <ListaFormaPago open={open} errorMsg={errors?.idFormaPago ?? ""} value={tipoPago?.idFormaPago ?? "0"} />

              <Grid item xs={12} sm={4} sx={{ mt: 2 }}>
                <IGasSwitchField
                  name='activo'
                  defaultValue={tipoPago.activo}
                  label='Activo'
                  disabled={loading.update}
                />
              </Grid>
            </IGasForm>
          )}
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation disabled={loadingButtons} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            color='secondary'
            disableElevation
            disabled={loadingButtons}
            type='submit'
            form='form-tipopago-update-dialog'
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se editó correctamente.' />
    </>
  );
}

export default UpdateDialog;

const useModalErrors = (open) => {
  const [errors, setErrors] = useState({});
  const { error } = useSelector((state) => state.tiposPagos);
  const assignErrors = () => {
    if (error.status === 400) setErrors(getModelErrors(error.message));
  };
  const resetErrors = () => {
    setErrors({});
  };

  useEffect(assignErrors, [error]);
  useEffect(resetErrors, [open]);

  return { errors };
};
