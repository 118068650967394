import { getStation } from "../../authService";
import api from "../service";

const controller = "tipopago/servicios";

export async function GetAll(parametros) {
  let params = {
    idEstacion: await getStation(),
    ...parametros,
  };
  const res = await api.get(`${controller}/all`, { params });
  return res.data;
}

export async function Insert(params) {
  let _p = {
    idEstacion: await getStation(),
    ...params,
  };
  const res = await api.post(`${controller}`, _p);
  return res.data;
}

export async function GetById(id) {
  const params = { id: id };
  const res = await api.get(`${controller}`, { params });
  return res.data;
}

export async function Update(params) {
  let _p = {
    idEstacion: await getStation(),
    ...params,
  };
  const res = await api.put(`${controller}`, _p);
  return res.data;
}

export async function Delete(id) {
  const params = { id: id };
  const res = await api.delete(`${controller}`, { params });
  return res.data;
}

export async function SelectClaseTipoPago() {
  const res = await api.get(`${controller}/SelectClaseTipoPago`);
  return res.data;
}

export async function SelectFormaPagoAdmin() {
  let params = {
    idEstacion: await getStation(),
  };
  const res = await api.get(`${controller}/SelectFormaPagoAdmin`, { params });
  return res.data;
}
