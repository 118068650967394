import { Catalogo, ColumnAlign, ColumnPriority, DangerMessage, WarningMessage } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAll, clearErrors, setUserIdSelected } from "../../store/catalogos/usuarios";
import { CheckCircleOutline, RadioButtonUnchecked } from "@mui/icons-material";
import {
  DeleteDialog,
  DetailDialog,
  EstacionesSelectDialog,
  FilterDrawer,
  InsertDialog,
  OptionTableRow,
  UpdateDialog,
} from "../../components/catalogos/usuarios";
import { Avatar, Tooltip, Typography } from "@mui/material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { orange } from "@mui/material/colors";

const CELL_CONFIRM = (value) =>
  value.confirmado ? (
    <CheckCircleOutline fontSize='small' color='primary' />
  ) : (
    <RadioButtonUnchecked fontSize='small' color='action' />
  );

const CELL_ADMINCONTRATO = (value) =>
  value.administradorContrato ? (
    <>
      <div>
        <Typography style={{ display: "inline" }}>{value.nombreCompleto}</Typography>
        <div style={{ display: "inline-block", marginLeft: "4px" }}>
          <Tooltip title='Adm. contrato'>
            <Avatar sx={{ width: 14, height: 14, bgcolor: orange[400] }}>
              <ManageAccountsIcon sx={{ width: "100%", height: "100%" }} />
            </Avatar>
          </Tooltip>
        </div>
      </div>
    </>
  ) : (
    <Typography>{value.nombreCompleto}</Typography>
  );

const TABLE_DEFAULT = {
  activeColumnName: "activo",
  columns: [
    { text: "Correo", name: "correo", colAlign: ColumnAlign.Left, linkDetail: true },
    {
      text: "Nombre",
      name: "nombreCompleto",
      colAlign: ColumnAlign.Left,
      orderDefault: true,
      colPriority: ColumnPriority.Medium,
      renderCell: CELL_ADMINCONTRATO,
    },
    {
      text: "Rol(es)",
      name: "roles",
      colAlign: ColumnAlign.Left,
      orderDefault: true,
      colPriority: ColumnPriority.Low,
    },
    {
      text: "Confirmado",
      name: "confirmado",
      colAlign: ColumnAlign.Center,
      colPriority: ColumnPriority.Normal,
      icon: true,
      renderCell: CELL_CONFIRM,
    },
    { text: "", name: "action", colAlign: ColumnAlign.Center, options: true, icon: true },
  ],
};

export function Usuarios() {
  const dispatch = useDispatch();
  const { loading, users, error, filter } = useSelector((state) => state.usuarios);

  const modalInsert = useModal(false);
  const modalEdit = useModal(false);
  const modalDetail = useModal(false);
  const modalDelete = useModal(false);
  const modalStations = useModal(false);

  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);

  const handleRefresh = (_) => dispatch(getAll(filter));
  const handleSelectUser = (id) => dispatch(setUserIdSelected(id));
  const handleError = () => {
    if (Boolean(error.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  useEffect(handleError, [error]);

  return (
    <>
      <Catalogo
        title='Usuarios'
        table={TABLE_DEFAULT}
        filter={filter}
        data={users}
        loading={loading.getAll}
        onSelect={handleSelectUser}
        onRefresh={handleRefresh}
        onInsert={modalInsert.open}
        onDetail={modalDetail.open}
        filterPanel={<FilterDrawer />}
        rowOptions={
          <OptionTableRow
            onDetail={modalDetail.open}
            onEdit={modalEdit.open}
            onDelete={modalDelete.open}
            onStation={modalStations.open}
          />
        }
      />

      <DetailDialog open={modalDetail.isShowing} onClose={modalDetail.close} postSubmit={handleRefresh} />
      <InsertDialog open={modalInsert.isShowing} onClose={modalInsert.close} postSubmit={handleRefresh} />
      <UpdateDialog open={modalEdit.isShowing} onClose={modalEdit.close} postSubmit={handleRefresh} />
      <DeleteDialog open={modalDelete.isShowing} onClose={modalDelete.close} postSubmit={handleRefresh} />
      <EstacionesSelectDialog open={modalStations.isShowing} onClose={modalStations.close} />

      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
    </>
  );
}
