import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  LinearProgress,
  Skeleton,
} from "@mui/material";
import { SuccessMessage } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { getId, remove } from "../../../store/catalogos/despachadores";
import { useForm } from "react-hook-form";
import { boldText } from "../../../helpers/utils";

export function DeleteDialog({ open, onClose, postSubmit }) {
  const dispatch = useDispatch();
  const { loading, user, error } = useSelector((state) => state.usuarios);
  const buttonsLoading = loading.delete || loading.getId;
  const successMsg = useModal(false);
  const { handleSubmit } = useForm();
  const [errors, setErrors] = useState({});

  const onSubmit = () => {
    dispatch(remove(user.id))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {});
  };

  function assignErrors() {
    if (open && error.status === 409) {
      setErrors(error.message);
      onClose();
    }
  }

  const handleClose = (event, reason) => {
    if (reason && reason == "backdropClick") return;
    onClose();
  };

  useDialog(open, onClose);
  useEffect(assignErrors, [error]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth={"xs"}>
        {loading.delete && <LinearProgress />}

        <DialogTitle>{"Eliminar despachador"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {loading.getId && <Skeleton animation='wave' width='100%' />}
            {!loading.getId && <>¿Desea eliminar el despachador {boldText(user?.nombreCompleto)}?</>}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disabled={buttonsLoading} onClick={onClose}>
            Cancelar
          </Button>
          <Button onClick={handleSubmit(onSubmit)} color='secondary' disabled={buttonsLoading} autoFocus>
            Eliminar
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage
        open={successMsg.isShowing}
        onClose={successMsg.close}
        text='Se eliminó el despachador correctamente.'
      />
    </>
  );
}

const useDialog = (open, onClose) => {
  const dispatch = useDispatch();
  const { userIdSelected } = useSelector((state) => state.usuarios);

  function fetch() {
    if (open) {
      dispatch(getId(userIdSelected)).then(hasDispatchError).catch(onClose);
    }
  }

  useEffect(fetch, [open]);
};
