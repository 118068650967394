import { useDispatch, useSelector } from "react-redux";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { useModal } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { createTheme, styled } from "@mui/material/styles";
import { LoadingPanel, SuccessMessage } from "@paul-igas/igas-react-components";
import { updateStations } from "../../../store/catalogos/usuarios";
import { hasDispatchError } from "../../../api/services/service";
import { getStations } from "../../../api/authService";

const Root = styled(List)(({ theme }) => ({
  width: "100%",
  maxHeight: "40vh",
  overflowY: "auto",
  backgroundColor: theme.palette.background.paper,
}));

const ROW_PAGE = 100;

export function EstacionesSelectDialog({ open, onClose }) {
  const dispatch = useDispatch();
  const { userIdSelected } = useSelector((state) => state.usuarios);
  const successMsg = useModal(false);
  const [stations, setStations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(0);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  function initDialog() {
    if (open) {
      setPage(0);
      setLoading(true);
      getStations(userIdSelected)
        .then(hasDispatchError)
        .then((res) => setStations(res))
        .then((_) => setLoading(false))
        .catch(onClose);
    }
  }

  function toggleStation(id) {
    const station = stations.find((station) => station.id === id);
    const index = stations.indexOf(station);
    const value = !stations[index].active;
    let newStations = [...stations];
    newStations[index] = { ...newStations[index], active: value };
    setStations(newStations);
  }

  function handleSubmit() {
    const params = {
      id: userIdSelected,
      stations: stations.filter((station) => station.active),
    };
    setLoading(true);
    dispatch(updateStations(params))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .catch(() => {});
  }

  function incrementPage() {
    setPage((page) => page + 1);
  }

  useEffect(() => {
    initDialog();
  }, [open, dispatch]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown maxWidth={"sm"} scroll={"paper"}>
        <DialogTitle onClose={onClose}>Estaciones de trabajo</DialogTitle>
        <DialogContent dividers={true} style={{ padding: 0, overflow: "hidden" }}>
          {loading && <LoadingPanel />}

          {!loading && (
            <Grid container spacing={2} direction='column'>
              <Grid item>
                <Alert severity='info'>Seleccione las estaciones de trabajo del usuario.</Alert>
              </Grid>
              <Grid item style={{ paddingTop: 0 }}>
                <StationList
                  stations={stations}
                  page={page}
                  toggleStation={toggleStation}
                  incrementPage={incrementPage}
                />
              </Grid>
            </Grid>
          )}
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation onClick={onClose}>
            Cancelar
          </Button>
          <Button color='secondary' disableElevation onClick={handleSubmit} disabled={loading}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se guardó correctamente.' />
    </>
  );
}

const StationList = ({ stations, page, toggleStation, incrementPage }) => {
  const ROWS = page * ROW_PAGE + ROW_PAGE;
  const TOTAL_ROWS = stations?.lenght;

  return (
    <Root>
      {stations &&
        stations
          .slice(0, ROWS)
          .map((station) => <StationItem key={station.id} station={station} toggleStation={toggleStation} />)}
      {TOTAL_ROWS > 0 && ROWS < TOTAL_ROWS && (
        <ListItem dense>
          <Button variant='contained' onClick={incrementPage} disableElevation style={{ width: "100%" }}>
            Mostrar mas
          </Button>
        </ListItem>
      )}
    </Root>
  );
};

const StationItem = ({ station, toggleStation }) => {
  const labelId = `checkbox-list-label-${station.id}`;

  const handleToggle = (id) => () => {
    toggleStation(id);
  };

  return (
    <ListItem role={undefined} dense button onClick={handleToggle(station.id)}>
      <ListItemIcon>
        <Checkbox
          edge='start'
          checked={station.active}
          tabIndex={-1}
          disableRipple
          color='primary'
          inputProps={{ "aria-labelledby": labelId }}
        />
      </ListItemIcon>
      <ListItemText id={labelId} primary={station.nombre} secondary={station.cre} />
    </ListItem>
  );
};
