import { getSubscription } from "../../authService";
import api from "../service";

export async function GetAll() {
    let params = {
        idSuscripcion: await getSubscription()
    }
    const res = await api.get("estacion/Servicios/selectEstaciones", { params });

    return res.data;
}

export default {
    GetAll
}