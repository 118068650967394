import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  MenuItem,
  Tooltip,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import {
  IGasForm,
  IGasMultiSelectField,
  IGasSelectAutocompleteField,
  IGasTextField,
  SuccessMessage,
} from "@paul-igas/igas-react-components";
import { useModal, getModelErrors } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { getRoles, getTimeZones, insert } from "../../../store/catalogos/usuarios";
import { useForm } from "react-hook-form";
import { Info } from "@mui/icons-material";

export function InsertDialog({ open, onClose, postSubmit }) {
  const dispatch = useDispatch();
  const successMsg = useModal(false);
  const { loading } = useSelector((state) => state.usuarios);
  const { stationTimeZone, timeZones, roles } = useModalFetch(open);
  const { errors } = useModalErrors(open);

  const { reset } = useForm();

  const FORM_DEFAULT = {
    correo: "",
    correoAdicional: "",
    nombreCompleto: "",
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const onSubmit = (data) => {
    dispatch(insert({ ...data, id: "" }))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {});
  };

  function initDialog() {
    if (open) {
      reset(FORM_DEFAULT);
    }
  }

  useEffect(initDialog, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown fullWidth maxWidth='sm' scroll='paper'>
        {loading.insert && <LinearProgress />}
        <DialogTitle onClose={onClose}>Agregar usuario</DialogTitle>

        <DialogContent dividers>
          <IGasForm id='form-usuario-insert-dialog' onSubmit={onSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <IGasTextField
                  autoFocus
                  label='Correo'
                  name='correo'
                  defaultValue={FORM_DEFAULT.correo}
                  disabled={loading.insert}
                  error={errors?.correo}
                  maxLength={50}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <IGasTextField
                  label='Correo adicional'
                  name='correoAdicional'
                  defaultValue={FORM_DEFAULT.correoAdicional}
                  disabled={loading.insert}
                  error={errors?.correoAdicional}
                  maxLength={50}
                  inputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <Tooltip
                          disableFocusListener
                          enterTouchDelay={1}
                          leaveTouchDelay={5000}
                          title='Se enviará a esta dirección de correo una copia de los correos electrónicos enviados al usuario.'
                        >
                          <Info color='primary' />
                        </Tooltip>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <IGasTextField
                  label='Nombre Completo'
                  name='nombreCompleto'
                  defaultValue={FORM_DEFAULT.nombreCompleto}
                  disabled={loading.insert}
                  error={errors?.nombreCompleto}
                  maxLength={70}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                {!stationTimeZone && <Skeleton />}
                {stationTimeZone && (
                  <IGasSelectAutocompleteField
                    label='Zona horaria'
                    name='zonaHoraria'
                    defaultValue={stationTimeZone}
                    error={errors?.zonaHoraria}
                    options={timeZones}
                    getOptionLabel={(option) => `${option?.name} (${option?.utc})`}
                    disabled={loading.insert}
                  />
                )}
              </Grid>

              <Grid item xs={12} sm={6}>
                <IGasMultiSelectField
                  label='Roles'
                  name='roles'
                  defaultValue={[]}
                  error={errors?.roles}
                  multiple
                  disabled={loading.insert}
                >
                  {roles.map((rol) => (
                    <MenuItem key={rol} value={rol}>
                      {rol}
                    </MenuItem>
                  ))}
                </IGasMultiSelectField>
              </Grid>
            </Grid>
          </IGasForm>
        </DialogContent>

        <DialogActions>
          <Button color='secondary' disableElevation disabled={loading.insert} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            color='secondary'
            disableElevation
            disabled={loading.insert}
            type='submit'
            form='form-usuario-insert-dialog'
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se guardó correctamente.' />
    </>
  );
}

const useModalFetch = (open) => {
  const dispatch = useDispatch();
  const [timeZones, setTimeZones] = useState([]);
  const { profile } = useSelector((state) => state.aplicacion);
  const [roles, setRoles] = useState([]);
  const init = async () => {
    if (open) {
      const [res, res2] = await Promise.all([dispatch(getRoles()), dispatch(getTimeZones())]);
      setRoles(res.payload);
      setTimeZones(res2.payload);
    }
  };

  useEffect(() => {
    init();
  }, [open, dispatch]);

  return {
    stationTimeZone: timeZones.filter((tz) => tz.name === profile.timeZonestacion)[0] ?? null,
    timeZones,
    roles,
  };
};

const useModalErrors = (open) => {
  const [errors, setErrors] = useState({});
  const { error } = useSelector((state) => state.usuarios);

  const assignErrors = () => {
    if (error?.status === 400) setErrors(getModelErrors(error.message));
  };
  const resetErrors = () => {
    setErrors({});
  };

  useEffect(assignErrors, [error]);
  useEffect(resetErrors, [open]);

  return { errors };
};

export default InsertDialog;
