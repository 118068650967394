import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, LinearProgress } from "@mui/material";
import { getModelErrors } from "@paul-igas/igas-react-hooks";
import { IGasForm, IGasTextField, SuccessMessage } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { insert } from "../../../store/catalogos/tiposPagos";
import { ListaClasePago, ListaFormaPago } from "./components";

const FORM_DEFAULT = {
  descripcion: "",
};

const idForm = "form-tipopago-insert-dialog";

export function InsertDialog({ open, onClose, postSubmit }) {
  const dispatch = useDispatch();
  const { loading, error } = useSelector((state) => state.tiposPagos);
  const [errors, setErrors] = useState({});
  const successMsg = useModal(false);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const onSubmit = (data) => {
    dispatch(insert(data))
      .then(hasDispatchError)
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {});
  };

  function initDialog() {
    if (open) {
      setErrors({});
    }
  }

  const assignErrors = () => {
    if (open & (error.status === 400)) setErrors(getModelErrors(error.message));
  };

  useEffect(initDialog, [open]);
  useEffect(assignErrors, [error]);

  return (
    <>
      <Dialog maxWidth='xs' open={open} onClose={handleClose} fullWidth disableEscapeKeyDown>
        {loading.insert && <LinearProgress />}
        <DialogTitle onClose={onClose}>Agregar tipo de pago</DialogTitle>
        <DialogContent dividers>
          <IGasForm id={idForm} onSubmit={onSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <IGasTextField
                  label='Descripción'
                  name='descripcion'
                  defaultValue={FORM_DEFAULT.descripcion}
                  disabled={loading.insert}
                  error={errors?.descripcion}
                  maxLength={50}
                  autoFocus
                />
              </Grid>
            </Grid>
            <ListaClasePago open={open} errorMsg={errors?.clase ?? ""} />
            <ListaFormaPago open={open} errorMsg={errors?.idFormaPago ?? ""} />
          </IGasForm>
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation disabled={loading.insert} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            color='secondary'
            disableElevation
            disabled={loading.insert}
            type='submit'
            form='form-tipopago-insert-dialog'
          >
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se agregó correctamente.' />
    </>
  );
}

export default InsertDialog;
