import { useEffect, useState } from "react";
import { Bitacora, DangerMessage, WarningMessage } from "@paul-igas/igas-react-components";
import { formatDateTimeUS, useModal } from "@paul-igas/igas-react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { DetailDialog } from "../../components/catalogos/usuarios";
import { FilterDrawer } from "../../components/consultas/bitacora";
import { clearErrors, getAll, getTypes, setFilter } from "../../store/consultas/bitacora";
import { getAll as getUsers, setUserIdSelected } from "../../store/catalogos/usuarios";

const USER_FILTER = {
  activo: { state: true, value: true },
};

export function BitacoraCon() {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const { loading, filter, logs, error } = useSelector((state) => state.bitacora);

  const userDetail = useModal(false);

  const warningMsg = useModal(false);
  const dangerMsg = useModal(false);

  const initFilter = (_users, _eventos) => {
    if (open) {

      let dteIni = new Date();
      let dteFin = new Date();
      const defaultFilter = {
        ...filter,
        fechaIni: {
          ...filter.fechaIni,
          state: true,
          value: formatDateTimeUS(dteIni.setDate(dteFin.getDate() - 1)),
        },
        fechaFin: {
          ...filter.fechaFin,
          state: true,
          value: formatDateTimeUS(dteFin),
        },
        idUsuario: {
          ...filter.idUsuario,
          state: false,
          // value: payload[0].id,
          value: _users[0].id,
        },
        tipoEvento: {
          ...filter.tipoEvento,
          state: false,
          value: _eventos[0].key,
        },
      };

      dispatch(setFilter(defaultFilter));
      setOpen(false);
    }
  };

  const handleError = () => {
    if (Boolean(error.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };

  const refreshParams = async () => {
    const [_usua, _evts] = await Promise.all([dispatch(getUsers(USER_FILTER)), dispatch(getTypes())]);
    initFilter(_usua.payload, _evts.payload);
  };

  const handleRefresh = (_) => dispatch(getAll(filter));

  const handleSelect = (id) => dispatch(setUserIdSelected(id));

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };

  useEffect(() => {
    refreshParams();
  }, []);

  useEffect(handleError, [error]);

  return (
    <>
      <Bitacora
        title='Bitácora'
        data={logs}
        loading={loading.getLogs}
        onRefresh={handleRefresh}
        onUserSelect={handleSelect}
        onUserDetail={userDetail.open}
        filter={filter}
        onRefreshFilter={refreshParams}
        filterPanel={<FilterDrawer />}
        showFilterData={false}
      />

      <DetailDialog open={userDetail.isShowing} onClose={userDetail.close} visibleOptions={false} />

      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
    </>
  );
}
