import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hasErrorRedux } from "../../api/services/service";
import { GetLogs, GetTypes } from "../../api/services/consultas/bitacora";
import { getOnlyActiveValues, formatDateTimeUS } from "@paul-igas/igas-react-hooks";

export const getAll = createAsyncThunk("bitacora/getAll", async (params, { rejectWithValue }) => {
  try {
    return await GetLogs(getOnlyActiveValues(params));
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getTypes = createAsyncThunk("bitacora/getTypes", async (_, { rejectWithValue }) => {
  try {
    return await GetTypes();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

const dteIni = new Date();
const dteFin = new Date();

const initialState = {
  logs: [],
  types: [],
  error: {},
  loading: {
    getLogs: false,
    getTypes: false,
  },
  filter: {
    fechaIni: {
      state: true,
      value: formatDateTimeUS(dteIni.setDate(dteFin.getDate() - 1)),
      label: "Fecha inicial",
    },
    fechaFin: {
      state: true,
      value: formatDateTimeUS(dteFin),
      label: "Fecha final",
    },
    idUsuario: { state: false, value: "", label: "Usuario" },
    tipoEvento: { state: false, value: "", label: "Evento" },
  },
};

const slice = createSlice({
  name: "bitacora",
  initialState,
  reducers: {
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state) => {
      state.loading.getLogs = true;
    });
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.error = {};
      state.loading.getLogs = false;
      state.logs = action.payload;
    });
    builder.addCase(getAll.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getLogs = false;
      state.logs = [];
    });
    // Types
    builder.addCase(getTypes.pending, (state) => {
      state.loading.getTypes = true;
    });
    builder.addCase(getTypes.fulfilled, (state, action) => {
      state.error = {};
      state.loading.getTypes = false;
      state.types = action.payload;
    });
    builder.addCase(getTypes.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getTypes = false;
      state.types = [];
    });
  },
});

export default slice.reducer;

export const { setFilter, clearErrors } = slice.actions;
