import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Paper,
} from "@mui/material";
import {
  ColumnAlign,
  ColumnType,
  DetailAlign,
  DetailDataType,
  DetailInfo,
  SimpleTable,
} from "@paul-igas/igas-react-components";
import { useModal, usePopper, zeroPad } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { getbyId } from "../../../store/operaciones/lecturasIniciales";
import { hasDispatchError } from "../../../api/services/service";
import EditDialog from "./editDialog";
import DeleteDialog from "./deleteDialog";
import { Backspace, Edit, ExpandMore } from "@mui/icons-material";

const TABLE_DEFAULT = {
  columns: [
    { text: "Dispensario", name: "dispensario", orderDefault: true },
    {
      text: "Lect. inicial",
      name: "totalizadorVolumen",
      colType: ColumnType.Decimal,
      digitos: 3,
      colAlign: ColumnAlign.Right,
    },
  ],
};

export function DetailDialog({ open, onClose, postSubmit, visibleOptions = true }) {
  const dispatch = useDispatch();
  const [mangueras, setMangueras] = useState([]);
  const { loading, lecturaInicialIdSelected, lecturaInicial } = useSelector((state) => state.lecturasIniciales);

  const modalEdit = useModal(false);
  const modalDelete = useModal(false);

  function handleDelete() {
    onClose();
    postSubmit();
  }

  function handleEdit() {
    initDialog();
    postSubmit();
  }

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const initDialog = () => {
    if (open) {
      dispatch(getbyId(lecturaInicialIdSelected))
        .then(({ payload }) => {
          setMangueras(
            payload.detalle?.map((d) => ({ ...d, dispensario: zeroPad(d.posCarga, 2) + " - " + d.combustibleDesc }))
          );
        })
        .then(hasDispatchError)
        .catch(() => {});
    }
  };

  useEffect(initDialog, [open]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown fullWidth maxWidth='xs' scroll='paper'>
        <DialogTitle onClose={handleClose}>Detalle de lecturas iniciales</DialogTitle>

        <DialogContent dividers>
          <>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={2}>
                <DetailInfo
                  title='Turno'
                  description={lecturaInicial?.noTurno}
                  loading={loading.getbyId}
                  dataType={DetailDataType.DataKey}
                  digitos={2}
                  dataAlign={DetailAlign.Center}
                />
              </Grid>

              <Grid item xs={6} sm={3}>
                <DetailInfo
                  title='Fecha turno'
                  description={lecturaInicial?.fechaTurno ?? new Date()}
                  loading={loading.getbyId}
                  dataType={DetailDataType.Date}
                  dataAlign={DetailAlign.Center}
                />
              </Grid>

              <Grid item xs={12} sm={7}>
                <DetailInfo title='Proveedor técnico' description={lecturaInicial?.tecnico} loading={loading.getbyId} />
              </Grid>

              <Grid item xs={12}>
                <DetailInfo title='Evento' description={lecturaInicial?.evento} loading={loading.getbyId} />
              </Grid>
            </Grid>

            <br />

            <Paper>
              <SimpleTable
                table={TABLE_DEFAULT}
                // data={lecturaInicial?.detalle ?? []}
                data={mangueras}
                showPagination={false}
                loading={loading.getbyId}
              />
            </Paper>
          </>
        </DialogContent>

        <DialogActions>
          {visibleOptions && <Options loading={loading.getbyId} onEdit={modalEdit.open} onDelete={modalDelete.open} />}
          <Button color='secondary' disableElevation onClick={handleClose}>
            Salir
          </Button>
        </DialogActions>
      </Dialog>
      <EditDialog open={modalEdit.isShowing} onClose={modalEdit.close} postSubmit={handleEdit} />
      <DeleteDialog open={modalDelete.isShowing} onClose={modalDelete.close} postSubmit={handleDelete} />
    </>
  );
}

export default DetailDialog;

function Options({ loading, onEdit, onDelete }) {
  const menu = usePopper(false);

  function handleEdit() {
    menu.close();
    onEdit();
  }
  function handleDelete() {
    menu.close();
    onDelete();
  }

  return (
    <>
      <Button onClick={menu.open} disabled={loading} endIcon={<ExpandMore />}>
        Opciones
      </Button>
      {Boolean(menu.anchorEl) && (
        <Menu open={menu.isShowing} anchorEl={menu.anchorEl} placement={"bottom-end"}>
          <ClickAwayListener onClickAway={menu.close}>
            <List dense={true}>
              <ListItemButton onClick={handleEdit}>
                <ListItemIcon>
                  <Edit fontSize='small' />
                </ListItemIcon>
                <ListItemText primary='Editar' />
              </ListItemButton>
              <ListItemButton onClick={handleDelete}>
                <ListItemIcon>
                  <Backspace fontSize='small' />
                </ListItemIcon>
                <ListItemText primary='Eliminar' />
              </ListItemButton>
            </List>
          </ClickAwayListener>
        </Menu>
      )}
    </>
  );
}
