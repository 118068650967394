import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hasErrorRedux } from "../../api/services/service";
import { GetLogoEstacion, UpdateLogoEstacion } from "../../api/services/herramientas/suscripcion";

export const updateLogoEstacion = createAsyncThunk("suscripcion/updateLogoEstacion", async (params, { rejectWithValue }) => {
  try {
    return await UpdateLogoEstacion(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getLogoEstacion = createAsyncThunk("suscripcion/selectLogoEstacion", async (_, { rejectWithValue }) => {
  try {
    return await GetLogoEstacion();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

const initialState = {
  logoEstacion: [],
  loading: {
    getLogo: false,
    updateLogo: false,
  },
};

const slice = createSlice({
  name: "suscripcion",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    //UpdateLogo
    builder.addCase(updateLogoEstacion.pending, (state) => {
      state.loading.updateLogo = true;
    });
    builder.addCase(updateLogoEstacion.fulfilled, (state) => {
      state.error = {};
      state.loading.updateLogo = false;
    });
    builder.addCase(updateLogoEstacion.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.updateLogo = false;
    });
    //GetLogo
    builder.addCase(getLogoEstacion.pending, (state) => {
      state.loading.getLogo = true;
    });
    builder.addCase(getLogoEstacion.fulfilled, (state, action) => {
      state.error = {};
      state.logoEstacion = action.payload;
      state.loading.getLogo = false;
    });
    builder.addCase(getLogoEstacion.rejected, (state, action) => {
      state.error = action.payload;
      state.logoEstacion = [];
      state.loading.getLogo = false;
    });
  },
});

export default slice.reducer;
export const { clearErrors } = slice.actions;
