import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hasErrorRedux } from "../../api/services/service";
import { GetAll, GetId, Update, GetTimeZones, Insert, Delete } from "../../api/services/catalogos/cajeros";
import { getOnlyActiveValues } from "@paul-igas/igas-react-hooks";

export const getAll = createAsyncThunk("servicios/SelectByRoleEstacion", async (params, { rejectWithValue }) => {
  try {
    return await GetAll(getOnlyActiveValues(params));
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getId = createAsyncThunk("usuarios/select", async (params, { rejectWithValue }) => {
  try {
    return await GetId(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const update = createAsyncThunk("usuarios/update", async (params, { rejectWithValue }) => {
  try {
    return await Update(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const getTimeZones = createAsyncThunk("usuarios/selectTimeZones", async (_, { rejectWithValue }) => {
  try {
    return await GetTimeZones();
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const insert = createAsyncThunk("usuarios/insert", async (params, { rejectWithValue }) => {
  try {
    return await Insert(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const remove = createAsyncThunk("usuarios/remove", async (params, { rejectWithValue }) => {
  try {
    return await Delete(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const initialState = {
  cajero: {},
  cajeros: [],
  error: {},
  filter: {
    activo: { state: true, value: true, label: "Activo" },
    confirmado: { state: false, value: false, label: "Confirmado" },
  },
  cajeroIdSelected: "",
  loading: {
    getAll: false,
    getId: false,
    insert: false,
    update: false,
    delete: false,
    getTimeZones: false,
  },
};

const slice = createSlice({
  name: "cajeros",
  initialState,
  reducers: {
    setCajeroIdSelected: (state, action) => {
      state.cajeroIdSelected = action.payload;
    },
    setFilter: (state, action) => {
      state.filter = action.payload;
    },
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAll.pending, (state) => {
      state.loading.getAll = true;
    });
    builder.addCase(getAll.fulfilled, (state, action) => {
      state.error = {};
      state.loading.getAll = false;
      state.cajeros = action.payload;
    });
    builder.addCase(getAll.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getAll = false;
      state.cajeros = [];
    });
    builder.addCase(getId.pending, (state) => {
      state.loading.getId = true;
    });
    builder.addCase(getId.fulfilled, (state, action) => {
      state.error = {};
      state.loading.getId = false;
      state.cajero = action.payload;
    });
    builder.addCase(getId.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.getId = false;
      state.cajero = {};
    });
    builder.addCase(insert.pending, (state) => {
      state.loading.insert = true;
    });
    builder.addCase(insert.fulfilled, (state, action) => {
      state.error = {};
      state.loading.insert = false;
      state.cajero = action.payload;
    });
    builder.addCase(insert.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.insert = false;
    });
    builder.addCase(update.pending, (state) => {
      state.loading.update = true;
    });
    builder.addCase(update.fulfilled, (state) => {
      state.error = {};
      state.loading.update = false;
    });
    builder.addCase(update.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.update = false;
    });
    builder.addCase(remove.pending, (state) => {
      state.loading.delete = true;
    });
    builder.addCase(remove.fulfilled, (state) => {
      state.error = {};
      state.loading.delete = false;
    });
    builder.addCase(remove.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.delete = false;
    });
  },
});

export default slice.reducer;

export const { setCajeroIdSelected, setFilter, clearErrors } = slice.actions;
