import { getStation } from "../../authService";
import api from "../service";

export async function GetAll() {
    let params = {
        idEstacion: await getStation()
    }
    const res = await api.get("productoDiverso/servicios/selectByEstacion", { params });
    return res.data;
}

export default {
    GetAll
}