
import React from 'react';
import {  ThemeProvider, styled } from '@mui/material/styles';
import Default from '../../themes/default';
import { CssBaseline } from '@mui/material';

const Root = styled('div')(() => ({
  height: '100vh',
  backgroundColor: '#eee'
}));

export function Anonymous(props) {
  const { children } = props;

  return (
    <ThemeProvider theme={Default}>
      <CssBaseline />
      <Root>
        <main>
          {children}
        </main>
      </Root>
    </ThemeProvider>
  );
}