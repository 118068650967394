import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectClaseTPList } from "../../../../store/catalogos/tiposPagos";
import { hasDispatchError } from "../../../../api/services/service";
import { Grid, MenuItem, Skeleton } from "@mui/material";
import { IGasSelectField } from "@paul-igas/igas-react-components";

export const ListaClasePago = ({ open = false, errorMsg = "", value = "0" }) => {
  const { clasesPago, loading } = useClasePago(open);

  return (
    <>
      {loading.selectCTPList && <Skeleton animation='wave' />}
      {!loading.selectCTPList && (
        <Grid container spacing={3} paddingTop={2}>
          <Grid item xs={12}>
            <IGasSelectField label='Clase de tipo de pago' name='clase' defaultValue={value} error={errorMsg}>
              <MenuItem value='0' disabled>
                <em>Seleccione una clase de tipo de pago</em>
              </MenuItem>
              {clasesPago.map((i) => (
                <MenuItem key={i.id} value={i.id}>
                  {i.descripcion}
                </MenuItem>
              ))}
            </IGasSelectField>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ListaClasePago;

const useClasePago = (open) => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.tiposPagos);
  const [clasesPago, setClasesPago] = useState([]);

  const initClasesPago = () => {
    if (open === true) {
      dispatch(selectClaseTPList())
        .then(hasDispatchError)
        .then(({ payload }) => setClasesPago(payload))
        .catch(() => {});
    }
  };

  useEffect(initClasesPago, [open]);

  return {
    clasesPago,
    loading,
  };
};
