import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Loading } from "./loading";
import { Anonymous } from "../../layout";

const signoutCallBack = (navigate) => {
  navigate("/");
};

export const SignOutOidc = () => {
  let navigate = useNavigate();

  useEffect(() => {
    signoutCallBack(navigate);
  });

  return (
    <Anonymous>
      <Loading />
    </Anonymous>
  );
};
