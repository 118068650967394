import { useEffect, useState } from "react";
import { UseAuthContext, UseAuthTheme } from "../../../contexts";
import { List, ListItemButton, ListItemText, Paper, Popper } from "@mui/material";
import { Button, ClickAwayListener, Skeleton, Tooltip, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { ExpandMore } from "@mui/icons-material";
import { getStations, login } from "../../../api/authService";
import { useDispatch, useSelector } from "react-redux";
import { DangerMessage, WarningMessage } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { hasDispatchError } from "../../../api/services/service";
import { clearErrors, UpdateEstacionTrabajo } from "../../../store/catalogos/usuarios";
import { ALERTMESSAGETYPES, setAlertMessage } from "../../../store/aplicacion";

const Name = styled(Typography)(() => ({
  fontSize: 12,
  maxWidth: "40vw",
}));

const Cre = styled(Typography)(() => ({
  fontSize: 9,
  maxWidth: "40vw",
}));

export default function MenuEstacion() {
  const theme = UseAuthTheme();
  const dispatch = useDispatch();
  const { profile, setBackdrop } = UseAuthContext();

  const [stations, setStations] = useState([]);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const { error } = useSelector((state) => state.usuarios);

  const handleError = () => {
    if (Boolean(error?.status)) {
      error?.status === 409 && dispatch(setAlertMessage({ type: ALERTMESSAGETYPES.WARNING, text: error?.message }));
      error?.status === 500 && dispatch(setAlertMessage({ type: ALERTMESSAGETYPES.DANGER, text: error?.message }));
    }
  };

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  }

  function handleClose() {
    setOpen(false);
  }

  function getWorkStation() {
    return stations?.find((i) => i.cre === profile?.estacion);
  }

  function updateWorkStation(cre) {
    setBackdrop(true);
    dispatch(UpdateEstacionTrabajo({ id: profile?.sub, cre: cre }))
      .then(hasDispatchError)
      .then(() => setBackdrop(false))
      .then(login)
      .catch(() => {});
  }

  useEffect(() => {
    async function init() {
      setBackdrop(true);
      const res = await getStations(profile.sub);
      setBackdrop(false);
      setStations(res);
    }
    init();
  }, []);

  useEffect(handleError, [error]);

  if ((stations?.length ?? 0) === 0)
    return (
      <div style={{ marginRight: 8 }}>
        <Skeleton animation='wave' width={150} />
        <Skeleton animation='wave' width={150} />
      </div>
    );

  return (
    <>
      <Tooltip title='Seleccione estación de trabajo'>
        <Button style={{ marginRight: 8 }} onClick={handleClick} endIcon={<ExpandMore />}>
          <div>
            <Name variant='button' noWrap display='block' align='right'>
              {getWorkStation()?.nombre.toUpperCase()}
            </Name>

            <Cre variant='caption' noWrap display='block' align='right'>
              {getWorkStation()?.cre.toUpperCase()}
            </Cre>
          </div>
        </Button>
      </Tooltip>
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement={"bottom-end"}
        sx={{
          zIndex: theme.zIndex.appBar + 1,
          [theme.breakpoints.up("sm")]: {
            zIndex: theme.zIndex.drawer + 2,
          },
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper
            sx={{
              maxWidth: "90vw",
            }}
          >
            <List dense>
              {stations?.length > 0 &&
                stations?.map((station) => (
                  <ListItemButton key={station.id} onClick={(_) => updateWorkStation(station.cre)}>
                    {station.active && <ListItemText primary={station.cre + " - " + station.nombre} />}
                  </ListItemButton>
                ))}
            </List>
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
}
