import api from "../service";

const _controller = "turnoliquidacion/servicios";

export async function GetUltimoAbierto() {
  const res = await api.get(`${_controller}/selectUltimoAbierto`);
  return res.data;
}

export default {
  GetUltimoAbierto,
};
