import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  LinearProgress,
  MenuItem,
  Tooltip,
  Alert,
  InputAdornment,
  Skeleton,
} from "@mui/material";
import {
  DetailInfo,
  IGasForm,
  IGasMultiSelectField,
  IGasSelectAutocompleteField,
  IGasSwitchField,
  IGasTextField,
  LoadingPanel,
  SuccessMessage,
} from "@paul-igas/igas-react-components";
import { useModal, getModelErrors } from "@paul-igas/igas-react-hooks";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { getId, getRoles, getTimeZones, update } from "../../../store/catalogos/usuarios";
import { useForm } from "react-hook-form";
import { Info } from "@mui/icons-material";
import { setProfile } from "../../../store/aplicacion";
import { boldText } from "../../../helpers/utils";

export function UpdateDialog({ open, onClose, postSubmit }) {
  const idForm = "form-usuario-update-dialog";
  const dispatch = useDispatch();
  const successMsg = useModal(false);
  const { loading } = useSelector((state) => state.usuarios);
  const { errors } = useModalErrors(open);
  const { modalValues } = useModalFetch(open);
  const { profile } = useSelector((state) => state.aplicacion);
  const self = profile.sub === modalValues.user?.id ?? "";

  function updateSelfProfile(data) {
    if (self) {
      const actualProfile = { ...profile, email: data.correoAdicional, fullName: data.nombreCompleto };
      dispatch(setProfile(actualProfile));
    }
  }

  const { reset } = useForm();

  const onSubmit = (data) => {
    const newData = { ...modalValues.user, ...data };
    dispatch(update(newData))
      .then(hasDispatchError)
      .then((_) => updateSelfProfile(data))
      .then(onClose)
      .then(successMsg.open)
      .then(postSubmit)
      .catch(() => {});
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  useEffect(() => {
    reset(modalValues.user);
  }, [modalValues]);

  return (
    <>
      <Dialog open={open} onClose={handleClose} disableEscapeKeyDown fullWidth={true} maxWidth={"sm"} scroll={"paper"}>
        {loading.update && <LinearProgress />}
        <DialogTitle onClose={onClose}>Editar usuario</DialogTitle>
        <DialogContent dividers>
          {!modalValues.user && <LoadingPanel />}
          {modalValues.user && (
            <IGasForm id='form-usuario-update-dialog' onSubmit={onSubmit}>
              <Grid container spacing={2}>
                {!modalValues.user?.confirmado && (
                  <Grid item xs={12}>
                    <Alert severity='warning'>Usuario no ha confirmado su correo electrónico.</Alert>
                  </Grid>
                )}

                {self && (
                  <Grid item xs={12}>
                    <Alert severity='info'>
                      Por cuestión de integridad no se permite editarse a sí mismo los campos {boldText("Roles")} y{" "}
                      {boldText("Activo")}.
                    </Alert>
                  </Grid>
                )}

                <Grid item xs={12} sm={6}>
                  <DetailInfo title='Correo' description={modalValues.user?.correo} />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <IGasTextField
                    label='Correo adicional'
                    name='correoAdicional'
                    defaultValue={modalValues.user?.correoAdicional}
                    disabled={loading.update}
                    error={errors?.correoAdicional}
                    maxLength={50}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <Tooltip
                            disableFocusListener
                            enterTouchDelay={1}
                            leaveTouchDelay={5000}
                            title='Se enviará a esta dirección de correo una copia de los correos electrónicos enviados al usuario.'
                          >
                            <Info color='primary' />
                          </Tooltip>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <IGasTextField
                    label='Nombre Completo'
                    name='nombreCompleto'
                    defaultValue={modalValues.user?.nombreCompleto}
                    disabled={loading.update}
                    error={errors?.nombreCompleto}
                    maxLength={70}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <IGasSelectAutocompleteField
                    label='Zona horaria'
                    name='zonaHoraria'
                    defaultValue={modalValues.user?.zonaHoraria}
                    error={errors?.zonaHoraria}
                    options={modalValues.timeZones ?? []}
                    getOptionLabel={(option) => `${option?.name} (${option?.utc})`}
                    disabled={loading.update}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  {modalValues.roles?.length === 0 && <Skeleton />}
                  {modalValues.roles?.length > 0 && (
                    <IGasMultiSelectField
                      label='Roles'
                      name='roles'
                      defaultValue={modalValues.user?.roles}
                      error={errors?.roles}
                      multiple
                      disabled={loading.update || self || modalValues.user.administradorContrato}
                    >
                      {modalValues.roles?.map((rol) => (
                        <MenuItem key={rol} value={rol}>
                          {rol}
                        </MenuItem>
                      ))}
                    </IGasMultiSelectField>
                  )}
                </Grid>

                <Grid item xs={12} md={6} sx={{ mt: 2 }}>
                  <IGasSwitchField
                    name='activo'
                    defaultValue={modalValues.user?.activo}
                    label='Activo'
                    disabled={self}
                  />
                </Grid>
              </Grid>
            </IGasForm>
          )}
        </DialogContent>
        <DialogActions>
          <Button color='secondary' disableElevation disabled={loading.update} onClick={onClose}>
            Cancelar
          </Button>
          <Button color='secondary' disableElevation disabled={loading.update} type='submit' form={idForm}>
            Guardar
          </Button>
        </DialogActions>
      </Dialog>
      <SuccessMessage open={successMsg.isShowing} onClose={successMsg.close} text='Se actualizó correctamente.' />
    </>
  );
}

const useModalFetch = (open) => {
  const dispatch = useDispatch();
  const { userIdSelected } = useSelector((state) => state.usuarios);
  const [modalValues, setModalValues] = useState({});

  const init = async () => {
    if (modalValues.user?.id !== userIdSelected) setModalValues({});

    if (open) {
      const [roles, timeZones, user] = await Promise.all([
        dispatch(getRoles()),
        dispatch(getTimeZones()),
        dispatch(getId(userIdSelected)),
      ]);

      setModalValues({
        roles: roles.payload,
        timeZones: timeZones.payload,
        user: user.payload,
      });
    }
  };

  useEffect(() => {
    init();
  }, [open, dispatch]);

  return { modalValues };
};

const useModalErrors = (open) => {
  const [errors, setErrors] = useState({});
  const { error } = useSelector((state) => state.usuarios);
  const assignErrors = () => {
    if (error?.status === 400) setErrors(getModelErrors(error.message));
  };
  const resetErrors = () => {
    setErrors({});
  };

  useEffect(assignErrors, [error]);
  useEffect(resetErrors, [open]);

  return { errors };
};

export default UpdateDialog;
