import { UseAuthContext, UseAuthTheme } from "../../../contexts";
import { AppBar, Toolbar, Typography, IconButton, Hidden, Tooltip, LinearProgress } from "@mui/material";
import { ExitToApp, Menu } from "@mui/icons-material";
import MenuUser from "./menuUser";
import MenuEstacion from "./menuEstacion";
import logo from "../../../assets/img/logos/appMenu.png";

export function AppBarTopMenu() {
  const theme = UseAuthTheme();
  const { leftMenu, title, profile, logoutDialog, progressBar } = UseAuthContext();

  return (
    <>
      <AppBar
        position='fixed'
        color='inherit'
        style={{ display: "flex" }}
        sx={{
          [theme.breakpoints.up("sm")]: {
            zIndex: theme.zIndex.drawer,
          },
          transition: theme.transitions.create(["margin", "widht"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
        }}
      >
        <Toolbar>
          <IconButton
            aria-label='open drawer'
            onClick={leftMenu.toggle}
            edge='start'
            style={{ marginRight: theme.spacing(2) }}
          >
            <Menu />
          </IconButton>

          <Hidden mdDown>
            <img src={logo} alt='logo' style={{ marginRight: theme.spacing(2) }} />
          </Hidden>

          <Hidden smDown>
            <Typography variant='h6' style={{ flexGrow: 1 }}>
              {title}
            </Typography>
          </Hidden>

          <Typography variant='h6' noWrap style={{ flexGrow: 1 }}></Typography>

          <MenuEstacion />

          <Hidden mdDown>
            <Typography variant='subtitle2' noWrap>
              {profile.fullName}
            </Typography>
          </Hidden>

          <MenuUser />

          <Tooltip title='Cerrar sesión'>
            <IconButton onClick={logoutDialog.open}>
              <ExitToApp />
            </IconButton>
          </Tooltip>
        </Toolbar>
        {progressBar && <LinearProgress />}
      </AppBar>
    </>
  );
}

export default AppBarTopMenu;
