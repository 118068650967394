import { getSubscription } from "../../authService";
import api from "../service";

const _controller = "usuarioEstacion/servicios";

export async function GetAll(parametros) {
  let params = {
    suscription: await getSubscription(),
    ...parametros,
  };
  const res = await api.get(`${_controller}/selectUsuarios`, { params });
  return res.data;
}

export async function GetId(id) {
  const params = { id: id };
  const res = await api.get(`${_controller}`, { params });
  return res.data;
}

export async function Insert(params) {
  const res = await api.post(`${_controller}`, params);
  return res.data;
}

export async function Update(params) {
  const res = await api.put(`${_controller}`, params);
  return res.data;
}

export async function Delete(id) {
  const params = { id: id };
  const res = await api.delete(`${_controller}`, { params });
  return res.data;
}

export async function GetRoles() {
  const res = await api.get(`${_controller}/selectRoles`);
  return res.data;
}

export async function GetTimeZones() {
  const res = await api.get(`${_controller}/selectTimeZones`);
  return res.data;
}

export async function UpdateStations(params) {
  const res = await api.put(`${_controller}/updateStations?id=${params.id}`, params.stations);
  return res.data;
}

export async function GetStations(id) {
  const params = { id: id };
  const res = await api.get(`${_controller}/SelectStations`, { params });
  return res.data;
}

export async function UpdateWorkStation(params) {
  const res = await api.put(`${_controller}/updateWorkStation`, null, { params });
  return res.data;
}

export async function SendConfirmEmail(id) {
  const params = { id: id };
  const res = await api.get(`${_controller}/enviarcorreo`, { params });
  return res.data;
}

export default {
  GetAll,
  GetId,
  Insert,
  Update,
  Delete,
  GetRoles,
  GetTimeZones,
  UpdateStations,
  SendConfirmEmail,
  UpdateWorkStation,
};
