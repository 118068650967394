import { Avatar, Button, Card, Grid, Paper } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import {DangerMessage} from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import placeholderImage from "../../../../assets/img/logos/Empty.png"

export const ConfLogo = ({ logo = "", onChangeLogo = () => {}, disabled }) => {
  var byteArray = [];
  const dangerMsg = useModal(false);

  const loadImage = () => {
    let input = document.createElement("input");
    input.type = "file";
    input.accept = "image/png";
    input.onchange = async (_) => {
      if (input.files.length > 0) {
        var _file = input.files[0];
        if (_file.type !== 'image/png') {
          dangerMsg.open();
          return;
        }
        byteArray = await fileToByteArray(_file);
        onChangeLogo(arrayBufferToBase64(byteArray));
      }
    };
    input.click((e) => e.preventDefault());
  };

  const handleCloseError = () => {
    dangerMsg.close();
  };

  return (
    <Grid container direction='row' spacing={1}>
      <Grid item>
        Logo de estación
        <Paper style={{ marginTop: '10px', textAlign: 'left' }}>
        <div
            style={{
              width: 125,
              height: 125,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: '0px',
              overflow: 'hidden',
              backgroundColor: '#f0f0f0',
            }}
          >
            <img
              src={logo ? `data:image/png;base64,${logo}` : placeholderImage}
              alt="logo"
              style={{ width: '100%', height: '100%', objectFit: 'contain' }}
            />
          </div>
        </Paper>
        <Button
            size='small'
            color='secondary'
            type='button'
            variant='contained'
            onClick={loadImage}
            startIcon={<CloudUploadIcon />}
            style={{ marginTop: '10px', textAlign: 'center' }}
            disabled={disabled}
          >
            Subir imagen
          </Button>
          <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={'Sólo se admiten archivos en formato PNG'} />
      </Grid>
    </Grid>

  );
};

export default ConfLogo;

function fileToByteArray(file) {
  return new Promise((resolve, reject) => {
    try {
      let reader = new FileReader();
      let fileByteArray = [];
      reader.readAsArrayBuffer(file);
      reader.onloadend = (evt) => {
        if (evt.target.readyState == FileReader.DONE) {
          let arrayBuffer = evt.target.result,
            array = new Uint8Array(arrayBuffer);
          for (var i = 0; i < array.length; i++) {
            fileByteArray.push(array[i]);
          }
        }
        resolve(fileByteArray);
      };
    } catch (e) {
      reject(e);
    }
  });
}

function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = [].slice.call(new Uint8Array(buffer));
  bytes.forEach((b) => (binary += String.fromCharCode(b)));
  return window.btoa(binary);
}
