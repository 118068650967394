import { Edit, WarningAmber } from "@mui/icons-material";
import { IconButton, Tooltip } from "@mui/material";

export function OptionTableRow(props) {
  const { data = {}, onEditLecturas = () => {} } = props;
  const liquidada = data.liquidada;
  const handleEdit = () => {
    onEditLecturas(data);
  };
  return (
    <>
      {liquidada && (
        <IconButton size='small'>
          <Tooltip title='Se encuentra liquidada' arrow>
            <WarningAmber color='error' />
          </Tooltip>
        </IconButton>
      )}

      {!liquidada && (
        <IconButton size='small' onClick={handleEdit}>
          <Tooltip title='Editar lectura'>
            <Edit />
          </Tooltip>
        </IconButton>
      )}
    </>
  );
}
