import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import aplicacion from "./aplicacion";
import combustibles from "./catalogos/combustibles";
import estaciones from "./catalogos/estaciones";
import usuarios from "./catalogos/usuarios";
import productosDiversos from "./catalogos/productosDiversos";
import tiposPagos from "./catalogos/tiposPagos";
import bitacora from "./consultas/bitacora";
import cajeros from "./catalogos/cajeros";
import despachadores from "./catalogos/despachadores";
import turnos from "./consultas/turnoLiquidacion";
import lecturasIniciales from "./operaciones/lecturasIniciales";
import configuracionEstacion from "./herramientas/configuracionEstacion";

const reducer = combineReducers({
  aplicacion,
  combustibles,
  estaciones,
  usuarios,
  productosDiversos,
  tiposPagos,
  bitacora,
  cajeros,
  despachadores,
  turnos,
  lecturasIniciales,
  configuracionEstacion
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
});

export default store;
