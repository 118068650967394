import { useEffect } from "react";
import { Catalogo, ColumnAlign, ColumnPriority, DangerMessage, WarningMessage } from "@paul-igas/igas-react-components";
import { useModal } from "@paul-igas/igas-react-hooks";
import { useDispatch, useSelector } from "react-redux";
import { getAll, clearErrors, setCajeroIdSelected } from "../../store/catalogos/cajeros";
import {
  FilterDrawer,
  OptionTableRow,
  UpdateDialog,
  DetailDialog,
  InsertDialog,
  DeleteDialog,
} from "../../components/catalogos/cajeros";
import { CheckCircleOutline, RadioButtonUnchecked } from "@mui/icons-material";

const CELL_CONFIRM = (value) =>
  value.confirmado ? (
    <CheckCircleOutline fontSize='small' color='primary' />
  ) : (
    <RadioButtonUnchecked fontSize='small' color='action' />
  );

const TABLE_DEFAULT = {
  activeColumnName: "activo",
  columns: [
    { text: "Correo", name: "correo", colAlign: ColumnAlign.Left, linkDetail: true },
    {
      text: "Nombre",
      name: "nombreCompleto",
      colAlign: ColumnAlign.Left,
      orderDefault: true,
      colPriority: ColumnPriority.Medium,
    },
    {
      text: "Confirmado",
      name: "confirmado",
      colAlign: ColumnAlign.Center,
      colPriority: ColumnPriority.Normal,
      icon: true,
      renderCell: CELL_CONFIRM,
    },
    { text: "", name: "action", colAlign: ColumnAlign.Center, options: true, icon: true },
  ],
};

export function Cajeros() {
  const dispatch = useDispatch();
  const { loading, cajeros, error, filter } = useSelector((state) => state.cajeros);

  const modalEdit = useModal(false);
  const modalDetail = useModal(false);
  const modalInsert = useModal(false);
  const modalDelete = useModal(false);
  const dangerMsg = useModal(false);
  const warningMsg = useModal(false);

  const handleRefresh = (_) => dispatch(getAll(filter));
  const handleSelectCajero = (id) => dispatch(setCajeroIdSelected(id));
  const handleError = () => {
    if (Boolean(error.status)) {
      error.status === 409 && warningMsg.open();
      error.status === 500 && dangerMsg.open();
    }
  };

  const handleCloseError = () => {
    dispatch(clearErrors());
    dangerMsg.close();
    warningMsg.close();
  };
  useEffect(handleError, [error]);
  return (
    <>
      <Catalogo
        title='Cajeros'
        table={TABLE_DEFAULT}
        filter={filter}
        data={cajeros}
        loading={loading.getAll}
        onSelect={handleSelectCajero}
        onRefresh={handleRefresh}
        onInsert={modalInsert.open}
        onDetail={modalDetail.open}
        filterPanel={<FilterDrawer />}
        rowOptions={<OptionTableRow onDetail={modalDetail.open} onEdit={modalEdit.open} onDelete={modalDelete.open} />}
      />
      <InsertDialog open={modalInsert.isShowing} onClose={modalInsert.close} postSubmit={handleRefresh} />
      <DetailDialog open={modalDetail.isShowing} onClose={modalDetail.close} postSubmit={handleRefresh} />
      <UpdateDialog open={modalEdit.isShowing} onClose={modalEdit.close} postSubmit={handleRefresh} />
      <DeleteDialog open={modalDelete.isShowing} onClose={modalDelete.close} postSubmit={handleRefresh} />
      <WarningMessage open={warningMsg.isShowing} onClose={handleCloseError} text={error?.message} />
      <DangerMessage open={dangerMsg.isShowing} onClose={handleCloseError} text={error?.message} />
    </>
  );
}
