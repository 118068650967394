import { createTheme } from '@mui/material/styles';
import { esES } from "@mui/material/locale";
import {amber, blueGrey} from '@mui/material/colors';

const Default = createTheme(
  {
    palette: {
      primary: {
        main: amber[800],
        contrastText: '#fff'
      },
      secondary: {
        main: blueGrey[800]
      }
    },
    typography: {
      button: {
        textTransform: 'none'
      }
    }
  },
  esES
);

export default Default;