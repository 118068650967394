import React from "react";
import { Route, Routes } from "react-router-dom";
import AuthRoute from "./components/authRoute";
import { NotFound, SigninOidc, SignOutOidc } from "./pages/auth";
import {
  Combustibles,
  Estaciones,
  ProductosDiversos,
  TiposPagos,
  Usuarios,
  Cajeros,
  Despachadores,
} from "./pages/catalogo";
import { BitacoraCon } from "./pages/consultas";
import DashBoard from "./pages/dashboard";
import { LecturasIniciales } from "./pages/operaciones";
import { ConfiguracionEstacion } from "./pages/herramientas";

function App() {
  return (
    <Routes>
      <Route element={<AuthRoute />}>
        <Route path='/' element={<DashBoard />} />
        {/* Catálogos */}
        <Route path='/catalogos/combustibles' element={<Combustibles />} />
        <Route path='/catalogos/estaciones' element={<Estaciones />} />
        <Route path='/catalogos/usuarios' element={<Usuarios />} />
        <Route path='/catalogos/cajeros' element={<Cajeros />} />
        <Route path='/catalogos/despachadores' element={<Despachadores />} />
        <Route path='/catalogos/productosdiversos' element={<ProductosDiversos />} />
        <Route path='/catalogos/tiposPagos' element={<TiposPagos />} />

        {/* {Operaciones} */}
        <Route path='/operaciones/lecturasIniciales' element={<LecturasIniciales />} />

        {/* Consultas */}
        <Route path='/consultas/bitacora' element={<BitacoraCon />} />

        {/* Herramientas */}
        <Route path='/herramientas/configuracionEstacion' element={<ConfiguracionEstacion />} />

        {/* En caso de que no exista la ruta, se carga esta página */}
        <Route path='*' element={<NotFound />} />
      </Route>

      <Route path='/signin-oidc' element={<SigninOidc />} />
      <Route path='/signout-oidc' element={<SignOutOidc />} />
    </Routes>
  );
}

export default App;
