import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { hasErrorRedux } from "../../api/services/service";
import { GetConfigurations, Update } from "../../api/services/herramientas/configuraciones";

export const update = createAsyncThunk("configuracionEstacion/update", async (params, { rejectWithValue }) => {
  try {
    return await Update(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

export const selectAll = createAsyncThunk("configuracionEstacion/select", async (params, { rejectWithValue }) => {
  try {
    return await GetConfigurations(params);
  } catch (error) {
    return rejectWithValue(hasErrorRedux(error));
  }
});

const initialState = {
  configuracionesEstacion: [],
  loading: {
    all: false,
    update: false,
  },
};

const slice = createSlice({
  name: "configuracionEstacion",
  initialState,
  reducers: {
    clearErrors: (state) => {
      state.error = {};
    },
  },
  extraReducers: (builder) => {
    builder.addCase(update.pending, (state) => {
      state.loading.update = true;
    });
    builder.addCase(update.fulfilled, (state) => {
      state.error = {};
      state.loading.update = false;
    });
    builder.addCase(update.rejected, (state, action) => {
      state.error = action.payload;
      state.loading.update = false;
    });
    // selectAll
    builder.addCase(selectAll.pending, (state) => {
      state.loading.all = true;
    });
    builder.addCase(selectAll.fulfilled, (state, action) => {
      state.error = {};
      state.configuracionesEstacion = action.payload;
      state.loading.all = false;
    });
    builder.addCase(selectAll.rejected, (state, action) => {
      state.error = action.payload;
      state.configuracionesEstacion = [];
      state.loading.all = false;
    });
  },
});

export default slice.reducer;
export const { clearErrors } = slice.actions;
