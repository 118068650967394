import {
  Button,
  ButtonGroup,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import { AccordionFilter } from "@paul-igas/igas-react-components";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { setFilter } from "../../../store/catalogos/usuarios";

export function FilterDrawer({ open, onClose }) {
  const dispatch = useDispatch();
  const { filter } = useSelector((state) => state.usuarios);
  const [filters, setFilters] = useState(filter);
  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    const array = Object.entries(filters);
    const values = array.map(([key, value]) => [
      key,
      { ...value, value: data[key] },
    ]);
    const result = Object.fromEntries(values);

    dispatch(setFilter(result));
    onClose();
  };

  function handleCheckChange(event) {
    const name = event.target.name;
    const checked = event.target.checked;
    const values = filters[name];

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: { ...values, state: checked },
    }));
  }

  return (
    <div style={{ width: "100%" }}>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <AccordionFilter
          name="activo"
          label="Activo"
          filters={filters}
          onChange={handleCheckChange}
        >
          <FormControl fullWidth size="small">
            <Controller
              name="activo"
              defaultValue={filters.activo.value}
              control={control}
              render={({ field }) => (
                <Select {...field} variant="standard">
                  <MenuItem value={true}>Sí</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </AccordionFilter>

        <AccordionFilter
          name="confirmado"
          label="Confirmado"
          filters={filters}
          onChange={handleCheckChange}
        >
          <FormControl fullWidth size="small">
            <Controller
              name="confirmado"
              defaultValue={filters.confirmado.value}
              control={control}
              render={({ field }) => (
                <Select {...field} variant="standard">
                  <MenuItem value={true}>Sí</MenuItem>
                  <MenuItem value={false}>No</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </AccordionFilter>

        <AccordionFilter
          name="role"
          label="Rol"
          filters={filters}
          onChange={handleCheckChange}
        >
          <FormControl fullWidth size="small">
            <Controller
              name="role"
              defaultValue={filters.role.value}
              control={control}
              render={({ field }) => (
                <Select {...field} variant="standard">
                  <MenuItem value={"Administrador"}>Administrador</MenuItem>
                  <MenuItem value={"Despachador"}>Despachador</MenuItem>
                  <MenuItem value={"Supervisor"}>Supervisor</MenuItem>
                  <MenuItem value={"Cajero"}>Cajero</MenuItem>
                </Select>
              )}
            />
          </FormControl>
        </AccordionFilter>

        <ButtonGroup
          disableElevation
          variant="contained"
          color="secondary"
          style={{
            marginTop: 16,
            marginBottom: 16,
            width: "100%",
          }}
          fullWidth
        >
          <Button onClick={onClose}>Cancelar</Button>
          <Button type="submit">Aplicar</Button>
        </ButtonGroup>
      </form>
    </div>
  );
}
