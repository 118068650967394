import { useEffect } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Skeleton,
  Switch,
} from "@mui/material";
import { DetailInfo } from "@paul-igas/igas-react-components";
import { useDispatch, useSelector } from "react-redux";
import { hasDispatchError } from "../../../api/services/service";
import { getById } from "../../../store/catalogos/tiposPagos";

export const DetailDialog = ({ open, onClose, postSubmit }) => {
  const dispatch = useDispatch();
  const { loading, tipoPago, tipoPagoIdSelected } = useSelector((state) => state.tiposPagos);

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") return;
    onClose();
  };

  const init = () => {
    if (open === true) {
      dispatch(getById(tipoPagoIdSelected)).then(hasDispatchError).catch(onClose);
    }
  };

  useEffect(init, [open]);

  return (
    <Dialog maxWidth='xs' fullWidth open={open} onClose={handleClose} disableEscapeKeyDown>
      <DialogTitle>Detalle de tipo de pago</DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <DetailInfo title='Descripción' description={tipoPago.descripcion} loading={loading.getById} />
          </Grid>
          <Grid item xs={12}>
            <DetailInfo
              title='Clase de tipo de pago'
              description={tipoPago.claseDescripcion}
              loading={loading.getById}
              alert={tipoPago.claseDescripcion === "No definido"}
            />
          </Grid>
          <Grid item xs={12}>
            <DetailInfo
              title='Forma de Pago'
              description={tipoPago.formaPagoDescripcion}
              loading={loading.getById}
              alert={tipoPago.formaPagoDescripcion === "No definido"}
            />
          </Grid>

          <Grid item xs={12}>
            {loading.getById && <Skeleton />}
            {!loading.getById && (
              <FormControlLabel
                disabled
                control={<Switch size='small' checked={tipoPago?.activo} />}
                label={<span style={{ fontSize: "0.85rem" }}>Activo</span>}
                sx={{ ["& .MuiFormControlLabel-label.Mui-disabled"]: { color: "black" } }}
              />
            )}
          </Grid>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color='secondary' disableElevation disabled={false} onClick={onClose}>
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DetailDialog;
