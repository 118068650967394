import { getSubscription } from "../../authService";
import api from "../service";

export async function GetAll(parametros) {
  let params = {
    suscription: await getSubscription(),
    ...parametros,
    role: "Despachador",
  };
  const res = await api.get("usuarioEstacion/servicios/selectUsuarios", { params });
  return res.data;
}

export async function GetId(id) {
  const params = { id: id };
  const res = await api.get("usuarioEstacion/servicios", { params });
  return res.data;
}

export async function Insert(params) {
  const res = await api.post("usuarioEstacion/servicios", params);
  return res.data;
}

export async function Update(params) {
  const res = await api.put("usuarioEstacion/servicios", params);
  return res.data;
}

export async function Delete(id) {
  const params = { id: id };
  const res = await api.delete("usuarioEstacion/servicios", { params });
  return res.data;
}

export async function GetTimeZones() {
  const res = await api.get("usuarioEstacion/servicios/selectTimeZones");
  return res.data;
}

export async function SendConfirmEmail(id) {
  const params = { id: id };
  const res = await api.get("usuarioEstacion/servicios/enviarcorreo", { params });
  return res.data;
}

export default {
  GetAll,
  GetId,
  Insert,
  Update,
  Delete,
  GetTimeZones,
  SendConfirmEmail,
};
